import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NextPageService, NextPageRoute } from '@cvx/nextpage';
import { CountryListComponent } from "./country-list/country-list.component";
import { PortListComponent } from "./port-list/port-list.component";
import { CountryComponent } from "./country/country.component";
import { PortComponent } from "./port/port.component";
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import { WpdMapComponent } from './wpdmap/wpdmap.component';
import { ProductListComponent } from "./product-list/product-list.component";
import { ProductComponent } from "./product/product.component";
import { ProductAlternateNameListComponent } from "./product-alternate-name-list/product-alternate-name-list.component";
import { ProductAlternateNameComponent } from "./product-alternate-name/product-alternate-name.component";
import { PortDetailComponent } from "./port-detail/port-detail.component";
import { PortHandlingListComponent } from "./port-handling-list/port-handling-list.component";
import { PortHandlingComponent } from "./port-handling/port-handling.component";
import { WPDAuthGuard } from "./auth.guard";
import { WPDRole } from "./models/wpdAuthUser";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { DeliveryAgentListComponent } from "./delivery-agent-list/delivery-agent-list.component";
import { DeliveryAgentComponent } from "./delivery-agent/delivery-agent.component";
import { FuelMapComponent } from "./fuel-map/fuel-map.component";
import { FuelPortComponent } from "./fuel-port/fuel-port.component";

export const routes: NextPageRoute[] = [
  {
    path: '',
    component: WpdMapComponent,
  },  
  {
    path: 'wpdmap',
    title: 'WPD Map',
    component: WpdMapComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [ OktaAuthGuard ],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },  
  {
    path: 'country',
    title: 'Countries',
    component: CountryListComponent,
    data: {
      permissions: {
        only: [WPDRole.admin, WPDRole.fuelAdmin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.viewer],
        redirectTo: 'unauthorized'
      }
    },
    canActivate: [ WPDAuthGuard ],
  },  
  {
    path: 'country/:id',    
    component: CountryComponent,
    data: {
      permissions: {
        only: [WPDRole.admin, WPDRole.fuelAdmin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.viewer],
        redirectTo: 'unauthorized'
      }
    },
    canActivate: [ WPDAuthGuard ],    
  },
  {
    path: 'delivery-agent',
    title: 'Delivery Agents',
    component: DeliveryAgentListComponent,
    data: {
      permissions: {
        only: [WPDRole.admin, WPDRole.fuelAdmin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.viewer],
        redirectTo: 'unauthorized'
      }
    },
    canActivate: [ WPDAuthGuard ],
  },
  {
    path: 'delivery-agent/:id',    
    component: DeliveryAgentComponent,
    data: {
      permissions: {
        only: [WPDRole.admin, WPDRole.fuelAdmin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.viewer],
        redirectTo: 'unauthorized'
      }
    },
    canActivate: [ WPDAuthGuard ],    
  },
  {
    path: 'port',
    title: 'Ports',
    component: PortListComponent,
    data: {
      permissions: {
        only: [WPDRole.admin, WPDRole.fuelAdmin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.viewer],
        redirectTo: 'unauthorized'
      }
    },
    canActivate: [ WPDAuthGuard ],
  },
  {
    path: 'port/:id',
    component: PortComponent,
    data: {
      permissions: {
        only: [WPDRole.admin, WPDRole.fuelAdmin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.viewer],
        redirectTo: 'unauthorized'
      }
    },
    canActivate: [ WPDAuthGuard ],
  },
  {
    path: 'portDetail/:id',
    component: PortDetailComponent,    
  },
  {
    path: 'product',
    title: 'Products',
    component: ProductListComponent,
    data: {
      permissions: {
        only: [WPDRole.admin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.viewer],
        redirectTo: 'unauthorized'
      }
    },
    canActivate: [ WPDAuthGuard ],
  },
  {
    path: 'product/:id',    
    component: ProductComponent,
    data: {
      permissions: {
        only: [WPDRole.admin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.viewer],
        redirectTo: 'unauthorized'
      }
    },
    canActivate: [ WPDAuthGuard ],
  },
  {
    path: 'portHandling/:portId/:productId/:daId',
    title: 'Port Handling',
    component: PortHandlingListComponent,
    data: {
      permissions: {
        only: [WPDRole.admin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.viewer],
        redirectTo: 'unauthorized'
      }
    },
    canActivate: [ WPDAuthGuard ],
  },
  {
    path: 'portHandling/:id',    
    component: PortHandlingComponent,
    canActivate: [ OktaAuthGuard ],
  },
  {
    path: 'altName',
    title: 'Product Alternate Names',
    component: ProductAlternateNameListComponent,
    data: {
      permissions: {
        only: [WPDRole.admin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.viewer],
        redirectTo: 'unauthorized'
      }
    },
    canActivate: [ WPDAuthGuard ],
  },
  {
    path: 'altName/:id',    
    component: ProductAlternateNameComponent,
    data: {
      permissions: {
        only: [WPDRole.admin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.viewer],
        redirectTo: 'unauthorized'
      }
    },
    canActivate: [ WPDAuthGuard ],
  },
  { path: '', component: WpdMapComponent, pathMatch: 'full' },
  {
    path: 'fuel-map',
    title: 'Fuel Map',
    component: FuelMapComponent,
  },
  {
    path: 'fuel-port/:id',
    component: FuelPortComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
  constructor(private NP: NextPageService) {
    this.NP.options.navigationRoutes = routes;
  }
}


<div class="form-horizontal">
    <div class="wpd-loading-shade"
        *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <h3>Country: {{data.name}} - {{data.id}}</h3>
    <form #loginForm="ngForm" (ngSubmit)="updateData()">

      <mat-form-field class="col-md-4">
        <mat-label>Region</mat-label>
        <mat-select [(ngModel)]="data.regionId" name="regionId">
          <mat-option *ngFor="let item of data.__Regions" [value]="item.value">{{item.text}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>Country Code</mat-label>
        <input matInput name="code" [(ngModel)]="data.code" type="text" />
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>Name</mat-label>
        <input matInput name="name" [(ngModel)]="data.name" type="text" />
      </mat-form-field>

      <mat-accordion>
        <mat-expansion-panel  (opened)="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Ports
            </mat-panel-title>
            <mat-panel-description>
              List of associated Ports
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="row" style="font-size: 12px;">
            <div class="col-sm-3" *ngFor="let p of data._Ports">
              <a [routerLink]="['/port', p.id]">{{p.name}} <mat-icon *ngIf="!p.visible" matTooltip="Not visible to customers">location_off</mat-icon></a>
            </div>              
          </div>        
        </mat-expansion-panel>        
      </mat-accordion>
     
      <mat-form-field class="col-md-12">
        <mat-label>Fuel Notes</mat-label>
        <textarea matInput name="fuelNotes" [(ngModel)]="data.fuelNotes" cdkTextareaAutosize></textarea>
      </mat-form-field>

      <mat-checkbox name="isActive" [(ngModel)]="data.isActive">Is Active?</mat-checkbox>

      <fieldset>
        <legend>Weekends:</legend>
        <mat-checkbox name="_Sunday" [(ngModel)]="data._Sunday">Sunday</mat-checkbox>
        <mat-checkbox name="_Monday" [(ngModel)]="data._Monday">Monday</mat-checkbox>
        <mat-checkbox name="_Tuesday" [(ngModel)]="data._Tuesday">Tuesday</mat-checkbox>
        <mat-checkbox name="_Wednesday" [(ngModel)]="data._Wednesday">Wednesday</mat-checkbox>
        <mat-checkbox name="_Thursday" [(ngModel)]="data._Thursday">Thursday</mat-checkbox>
        <mat-checkbox name="_Friday" [(ngModel)]="data._Friday">Friday</mat-checkbox>
        <mat-checkbox name="_Saturday" [(ngModel)]="data._Saturday">Saturday</mat-checkbox>
      </fieldset>

      <div class="padding-top-24" *ngIf="data.canUpdate">
        <div class="col-md-offset-2 col-md-12">
          <div [ngClass]="'alert ' + result.e == null ?'alert-danger' : 'alert-success'" [innerHTML]="result.e??result.i"></div>
          <button mat-raised-button color="primary" type="submit">Save</button>
        </div>
      </div>
    </form>
</div>


<div class="form-horizontal">
    <div class="wpd-loading-shade"
        *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <a [routerLink]="['/portDetail', data.id]" class="float-right">External View</a>
    <h3 mat-dialog-title>Port: {{data.id}} / Plant Num: {{data._SAP_PlantID}} / Sales Org: {{data._SalesOrg}} 
        <a [routerLink]="['/portHandling', data.id, null, null]" class="phLink" matTooltip="Port Handling"><mat-icon>link</mat-icon></a>
        <button mat-icon-button mat-dialog-close class="float-right" *ngIf="isDialog"><mat-icon>close</mat-icon></button>
    </h3>   
    <form #loginForm="ngForm" (ngSubmit)="updateData()" mat-dialog-content>

      <mat-form-field class="col-md-6">
        <mat-label>Name</mat-label>
        <input matInput name="_Name" [(ngModel)]="data._Name" type="text" readonly />
      </mat-form-field>
      <mat-form-field class="col-md-5">
        <mat-label>Delivery Agent</mat-label>
        <input matInput name="_DeliveryAgentName" [(ngModel)]="data._DeliveryAgentName" type="text" readonly />
      </mat-form-field>
      <a class="col-md-1" color="primary" matTooltip="Delivery Agent View" [routerLink]="['/delivery-agent', data._DeliveryAgentId]" target="_blank">
        <mat-icon>open_in_new</mat-icon>
      </a>
      <mat-form-field class="col-md-5">
        <mat-label>Country</mat-label>
        <mat-select [(value)]="data.countryId" name="regionId">
          <mat-option *ngFor="let item of data.__Countries" [value]="item.value">{{item.text}}</mat-option>
        </mat-select>        
      </mat-form-field>
      <a class="col-md-1" color="primary" matTooltip="Country View" [routerLink]="['/country', data.countryId]" target="_blank">
        <mat-icon>open_in_new</mat-icon>
      </a>
      <mat-form-field class="col-md-6">
        <mat-label>CGL Days Notice</mat-label>
        <input matInput name="cglDaysNotice" [(ngModel)]="data.cglDaysNotice" type="text" />
      </mat-form-field>
      <div class="row">      
        <fieldset class="col-md-12" style="border: 1px dashed;">
          <legend>Flags:</legend>
          <mat-checkbox name="isHallmark" [(ngModel)]="data.isHallmark">Is Hallmark?</mat-checkbox>
          <mat-checkbox name="isThirdParty" [(ngModel)]="data.isThirdParty">Is Third Party?</mat-checkbox>
          <mat-checkbox name="isShipyard" [(ngModel)]="data.isShipyard">Is Shipyard?</mat-checkbox>
          <mat-checkbox name="_IsActive" [(ngModel)]="data._IsActive" [disabled]="true">Is Active?</mat-checkbox>
          <mat-checkbox name="barge" [(ngModel)]="data.barge">Barge?</mat-checkbox>
        </fieldset>         
    </div>
    <div class="row">
      <mat-form-field class="col-md-2">
        <mat-label>Lat</mat-label>
        <input matInput name="lat" [(ngModel)]="data.lat" type="number" />
      </mat-form-field>
      <mat-form-field class="col-md-2">
        <mat-label>Lng</mat-label>
        <input matInput name="lng" [(ngModel)]="data.lng" type="number" />
      </mat-form-field>
      <mat-form-field class="col-md-2">
        <mat-label>Pricing Area</mat-label>
        <input matInput name="_PricingArea" [(ngModel)]="data._PricingArea" type="text" readonly/>
      </mat-form-field>
      <mat-form-field class="col-md-2">
        <mat-label>Pricing Division</mat-label>
        <input matInput name="_PricingDivision" [(ngModel)]="data._PricingDivision" type="text" readonly/>
      </mat-form-field>
      <mat-form-field class="col-md-2">
        <mat-label>Pricing Zone</mat-label>
        <input matInput name="_PricingZone" [(ngModel)]="data._PricingZone" type="text" readonly/>
      </mat-form-field>
      <mat-form-field class="col-md-2">
        <mat-label>State Code</mat-label>
        <input matInput name="_StateCode" [(ngModel)]="data._StateCode" type="text" readonly/>
      </mat-form-field>
    </div>

      <mat-form-field class="col-md-12">
        <mat-label>Additional Lead Time
          <mat-icon *ngIf="data._DA_AdditionalLeadTime"
            fontIcon="{{(!data.additionalLeadTime)? 'supervisor_account': 'info'}}" 
            class="{{(!data.additionalLeadTime)? 'text-danger': 'text-info p-tooltip'}}" 
            matTooltip="{{data._DA_AdditionalLeadTime}}" matTooltipClass="html-tooltip"></mat-icon>
        </mat-label>
        <textarea matInput name="additionalLeadTime" [(ngModel)]="data.additionalLeadTime" cdkTextareaAutosize></textarea>
      </mat-form-field>
      <mat-form-field class="col-md-12">
        <mat-label>Pumping from Packages Available          
          <mat-icon *ngIf="data._DA_PumpingAvailability"
            fontIcon="{{(!data.pumpingAvailability)? 'supervisor_account': 'info'}}" 
            class="{{(!data.pumpingAvailability)? 'text-danger': 'text-info p-tooltip'}}" 
            matTooltip="{{data._DA_PumpingAvailability}}" matTooltipClass="html-tooltip"></mat-icon>
        </mat-label>
        <textarea matInput name="pumpingAvailability" [(ngModel)]="data.pumpingAvailability" cdkTextareaAutosize></textarea>
      </mat-form-field>
      <mat-form-field class="col-md-12">
        <mat-label>Additional Charges
          <mat-icon *ngIf="data._DA_AdditionalCharges"
            fontIcon="{{(!data.additionalCharges)? 'supervisor_account': 'info'}}" 
            class="{{(!data.additionalCharges)? 'text-danger': 'text-info p-tooltip'}}" 
            matTooltip="{{data._DA_AdditionalCharges}}" matTooltipClass="html-tooltip"></mat-icon>
        </mat-label>
        <textarea matInput name="additionalCharges" [(ngModel)]="data.additionalCharges" cdkTextareaAutosize></textarea>
      </mat-form-field>
      <mat-form-field class="col-md-12">
        <mat-label>Other Information          
          <mat-icon *ngIf="data._DA_OtherInformation"
            fontIcon="{{(!data.otherInformation)? 'supervisor_account': 'info'}}" 
            class="{{(!data.otherInformation)? 'text-danger': 'text-info p-tooltip'}}" 
            matTooltip="{{data._DA_OtherInformation}}" matTooltipClass="html-tooltip"></mat-icon>
        </mat-label>
        <textarea matInput name="otherInformation" [(ngModel)]="data.otherInformation" cdkTextareaAutosize></textarea>
      </mat-form-field>

      <mat-label class="col-md-2">Min. Order Qty</mat-label>
      <div class="row">                
          <table class="table-MOQ" border="1" aria-describedby="Minimum order quantity">
            <thead>
              <tr><th scope="col" colspan="3" style="text-align:center">Customer</th></tr>
              <tr><th scope="col"></th><th scope="col">Barge/Launch</th><th scope="col">Truck</th></tr>
            </thead>
            <tbody>
              <tr>
                <td>Bulk</td>
                <td>
                  <textarea matInput name="moqBulkBarge" [(ngModel)]="data.moqBulkBarge" cdkTextareaAutosize></textarea>
                  <mat-icon *ngIf="data._DA_MoqBulkBarge" fontIcon="{{!data.moqBulkBarge?'supervisor_account': 'info'}}" class="{{!data.moqBulkBarge?'text-danger': 'text-info'}} parent-tooltip" matTooltip="{{data._DA_MoqBulkBarge}}" matTooltipClass="html-tooltip"></mat-icon>
                </td>
                <td>
                  <textarea matInput name="moqBulkTruck" [(ngModel)]="data.moqBulkTruck" cdkTextareaAutosize></textarea>                  
                  <mat-icon *ngIf="data._DA_MoqBulkTruck" fontIcon="{{!data.moqBulkTruck?'supervisor_account': 'info'}}" class="{{!data.moqBulkTruck?'text-danger': 'text-info'}} parent-tooltip" matTooltip="{{data._DA_MoqBulkTruck}}" matTooltipClass="html-tooltip"></mat-icon>
                </td>
              </tr>
              <tr>
                <td>Pack</td>
                <td>
                  <textarea matInput name="moqPackBarge" [(ngModel)]="data.moqPackBarge" cdkTextareaAutosize></textarea>                  
                  <mat-icon *ngIf="data._DA_MoqPackBarge" fontIcon="{{!data.moqPackBarge?'supervisor_account': 'info'}}" class="{{!data.moqPackBarge?'text-danger': 'text-info'}} parent-tooltip" matTooltip="{{data._DA_MoqPackBarge}}" matTooltipClass="html-tooltip"></mat-icon>
                </td>
                <td>
                  <textarea matInput name="moqPackTruck" [(ngModel)]="data.moqPackTruck" cdkTextareaAutosize></textarea>                  
                  <mat-icon *ngIf="data._DA_MoqPackTruck" fontIcon="{{!data.moqPackTruck?'supervisor_account': 'info'}}" class="{{!data.moqPackTruck?'text-danger': 'text-info'}} parent-tooltip" matTooltip="{{data._DA_MoqPackTruck}}" matTooltipClass="html-tooltip"></mat-icon>
                </td>
              </tr>
              <tr>
                <td>Combine</td>
                <td>
                  <textarea matInput name="moqAllBarge" [(ngModel)]="data.moqAllBarge" cdkTextareaAutosize></textarea>                  
                  <mat-icon *ngIf="data._DA_MoqAllBarge" fontIcon="{{!data.moqAllBarge?'supervisor_account': 'info'}}" class="{{!data.moqAllBarge?'text-danger': 'text-info'}} parent-tooltip" matTooltip="{{data._DA_MoqAllBarge}}" matTooltipClass="html-tooltip"></mat-icon>
                </td>
                <td>
                  <textarea matInput name="moqAllTruck" [(ngModel)]="data.moqAllTruck" cdkTextareaAutosize></textarea>                  
                  <mat-icon *ngIf="data._DA_MoqAllTruck" fontIcon="{{!data.moqAllTruck?'supervisor_account': 'info'}}" class="{{!data.moqAllTruck?'text-danger': 'text-info'}} parent-tooltip" matTooltip="{{data._DA_MoqAllTruck}}" matTooltipClass="html-tooltip"></mat-icon>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
      <mat-form-field class="col-md-12">
        <mat-label>          
          Foot notes
          <mat-icon *ngIf="!data.footNotes && data._DA_FootNotes" aria-hidden="false" fontIcon="supervisor_account" class="text-danger" matTooltip="{{data._DA_FootNotes}}" matTooltipClass="html-tooltip"></mat-icon>
        </mat-label>
        <textarea matInput name="footNotes" [(ngModel)]="data.footNotes" cdkTextareaAutosize></textarea>
      </mat-form-field>
      <div class="row padding-top-24">
        <table border="1" class="table-MOQ col-md-2" aria-describedby="One port minimum order quantity">
          <thead><tr><th colspan="2" style="text-align:center">One Port</th></tr></thead>          
          <tbody>
            <tr>
              <td>Bulk</td>
              <td><input matInput name="bulkMOQ" [(ngModel)]="data.bulkMOQ" type="number" placeholder="Bulk MOQ in Lt/KG" /></td>
            </tr>
            <tr>
              <td>Pack</td>
              <td><input matInput name="packMOQ" [(ngModel)]="data.packMOQ" type="number" placeholder="Pack MOQ in Lt/KG" /></td>
            </tr>
            <tr>
              <td>Tote</td>
              <td><input matInput name="toteMOQ" [(ngModel)]="data.toteMOQ" type="number" placeholder="Tote MOQ in Lt/KG" /></td>
            </tr>
            <tr>
              <td>Drum</td>
              <td><input matInput name="drumMOQ" [(ngModel)]="data.drumMOQ" type="number" placeholder="Drum MOQ in Lt/KG" /></td>
            </tr>
            <tr>
              <td>Pail</td>
              <td><input matInput name="pailMOQ" [(ngModel)]="data.pailMOQ" type="number" placeholder="Pail MOQ in Lt/KG" /></td>
            </tr>
            <tr>
              <td>Case</td>
              <td><input matInput name="caseMOQ" [(ngModel)]="data.caseMOQ" type="number" placeholder="Case MOQ in Lt/KG" /></td>
            </tr>
          </tbody>
        </table>

      </div>
      <mat-accordion>
        <mat-expansion-panel  (opened)="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Fuels Data
            </mat-panel-title>
            <mat-panel-description>
              Fuels related data
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <mat-form-field class="col-md-12">
              <mat-label>Fuels Days Notice</mat-label>
              <input matInput name="fuelDaysNotice" [(ngModel)]="data.fuelDaysNotice" type="text" />
            </mat-form-field>
            <mat-form-field class="col-md-4">
              <mat-label>Fuel Contact Name</mat-label>
              <input matInput name="fuelContactName" [(ngModel)]="data.fuelContactName" type="text" />
            </mat-form-field>
            <mat-form-field class="col-md-4">
              <mat-label>Fuel Contact Phone</mat-label>
              <input matInput name="fuelContactPhone" [(ngModel)]="data.fuelContactPhone" type="text" />
            </mat-form-field>
            <mat-form-field class="col-md-4">
              <mat-label>Fuel Contact Email</mat-label>
              <input matInput name="fuelContactEmail" [(ngModel)]="data.fuelContactEmail" type="email" />
            </mat-form-field>
            <mat-form-field class="col-md-12">
              <mat-label>Fuel Notes</mat-label>
              <textarea matInput name="fuelNotes" [(ngModel)]="data.fuelNotes" cdkTextareaAutosize></textarea>
            </mat-form-field>
          </div>
          
        </mat-expansion-panel>        
      </mat-accordion>

      <div class="padding-top-24" *ngIf="data.canUpdate" mat-dialog-actions>
        <div class="col-md-offset-2 col-md-12">
          <div [ngClass]="'alert ' + result.e == null ?'alert-danger' : 'alert-success'" [innerHTML]="result.e??result.i"></div>
          <button mat-raised-button color="primary" type="submit">Save</button>
        </div>
      </div>
    </form>
</div>


import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { DefaultValueAccessor, NgControl } from '@angular/forms';
import { BaseCacheDirective } from './base-cache.directive';


@Directive({
    selector: '[cvx-input-cache] [formControl]'
})

export class InputCacheDirective extends BaseCacheDirective<string> {
    constructor(el: ElementRef, private acc: DefaultValueAccessor, private control: NgControl) {
      super(el, "cvx-input-cache")
    }
   
    ngOnInit(): void {
      if(this.cvxCache) {
        this.acc.writeValue(this.cvxCache);
        this.acc.onChange(this.cvxCache);
      }
      this.control.valueChanges?.subscribe((value: any)=> {
        this.cvxCache = value;
      });
    }

  }
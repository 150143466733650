import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { MapService } from '../services/map.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';
import { APIResultVM } from '../models/apiResultVM';
import { FuelPortVM } from '../models/fuelPortVM';

@Component({
  selector: 'app-fuel-port',
  templateUrl: './fuel-port.component.html',
  styleUrls: ['./fuel-port.component.css']
})
export class FuelPortComponent extends BaseComponent implements OnInit {

  constructor(protected service: MapService, protected route: ActivatedRoute, protected snackBar: MatSnackBar, authService: AuthService) {
    super();
    authService.loadPermissionsFromSession();
  }
  id: BigInteger;
  isLoadingResults = true;
  isDialog = false;
  data?: FuelPortVM;  
  
  ngOnInit() {

    this.route.paramMap.subscribe(paramMap => {
      this.id = <BigInteger><unknown>(paramMap.get('id'));
      this.isLoadingResults = true;
      return this.service.getFuelPortDetail(this.id).subscribe({
        next: (resp: APIResultVM<FuelPortVM>) => {
          if (resp.e != null) {
            this.snackBar.open(resp.e ?? "", "Error");
          }
          else if (resp.i != null) {
            this.snackBar.open(resp.i ?? "Some info", "Info", { duration: 2 * 1000 });
          }
          else {
            this.data = resp.o;            
          }          
        },
        error: (e) => { 
          console.log(e); 
          this.isLoadingResults = false;
          this.snackBar.open("Unknown error, please contact administrator!!!", "Error");
        },
        complete: () => { this.isLoadingResults = false; },
      });
    });
  }

 

}

import { Component, Inject } from '@angular/core';
import { BaseUpsert } from '../common/base-upsert.component';
import { CountryService } from '../services/country.service';
import { CountryVM } from '../models/countryVM';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
export type DialogDataSubmitCallback<T> = (row: T) => void;

@Component({
  selector: 'wpd-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})

export class CountryComponent extends BaseUpsert<CountryVM> {  
  constructor(service: CountryService, route: ActivatedRoute, snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) contextData?: { callback: DialogDataSubmitCallback<CountryVM>; id: BigInteger },
    dialogRef?: MatDialogRef<CountryComponent>
  ) {
    super(service, route, snackBar, contextData, dialogRef);
  }
}

<div class="wpd-loading-shade" *ngIf="isLoadingResults">
  <mat-spinner mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<div class="mat-elevation-z8">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon matTooltip="Map view">place</mat-icon>
        </ng-template>
        <google-map #gMap height="450px"
        width="100%"
        [zoom]="zoom"
        [center]="center"
        [options]="options"       
        (zoomChanged)="mapZoomChanged()"
        (centerChanged)="mapCenterChanged()"
        (idle)="mapIdle()">        
            <map-marker-clusterer 
            [gridSize]=28 
            [maxZoom]=5             
            imagePath="assets/images/m">
                <map-marker
                  #markerElem="mapMarker"
                  *ngFor="let marker of markers; let i = index;"                
                  [position]="marker.position"
                  [label]="marker.label"
                  [title]="marker.title"
                  [options]="marker.options"
                  [icon]="marker.icon"
                  (mapClick)="openPopup(markerElem, marker)"
                  (mapMouseover)="mapShowMarkerInfo(marker)"
                  (mapMouseout)="mapShowMarkerInfo(null)">                  
                </map-marker>
            </map-marker-clusterer>            
        </google-map>
        <div class="hidden-xs">
          <div class="hmKey row">
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                  <div class="portsIcon">5</div>
                  <div class="hmPortsDesc">Chevron Fuel Ports</div>
              </div>
              <div class="col-xl-9 col-lg-9 col-md-6 col-sm-12 col-12">
                  <div class="portIcon"></div>
                  <div class="hmPortDesc">Chevron Fuel Port</div>
                </div>
          </div>   
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon matTooltip="Tabular view">list</mat-icon>
        </ng-template>

        <div class="mat-elevation-z8">
            <mat-form-field>
              <mat-label>Filter</mat-label>
              <input matInput placeholder="Ex. USA" [formControl]="searchKeywordFilter" (keyup)="applyFilter()">
            </mat-form-field>
          
            <div>
              <table mat-table [dataSource]="tableData" matSort matSortActive="name" matSortDirection="asc" aria-describedby="Port list">                
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Port</th>
                  <td mat-cell *matCellDef="let row"><a [routerLink]="['/fuel-port', row.id]">{{row.name}}</a></td>
                </ng-container>
                <ng-container matColumnDef="portNotes">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Port Notes</th>
                  <td mat-cell *matCellDef="let row">
                    <ng-container [ngTemplateOutlet]="portNotesTemplate"
                      [ngTemplateOutletContext]="{notes:row.portNotes?.split('\r\n')}">
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="daysNotice">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Notice</th>
                  <td mat-cell *matCellDef="let row">{{row.daysNotice}}</td>
                </ng-container>
                <ng-container matColumnDef="contactEmail">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>contact Email</th>
                  <td mat-cell *matCellDef="let row">{{row.contactEmail}}</td>
                </ng-container>
                <ng-container matColumnDef="contactName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact Name</th>
                  <td mat-cell *matCellDef="let row">{{row.contactName}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data matching the filter "{{searchKeywordFilter.value}}"</td>
                </tr>
              </table>
            </div>
          </div>
    </mat-tab>
  </mat-tab-group> 
</div>


<ng-template #portNotesTemplate let-notes="notes">
  <ul>
     <li *ngFor="let item of notes" [ngClass]="{'list-style-none': item?.startsWith('\t')}">{{item}}</li>
  </ul>
</ng-template>


export interface WPDAuthUser
{
  userID: string
  roles: WPDRole[]
        
}
export enum WPDRole {
  admin = '0',
  fuelAdmin= '1',
  portAdmin = '2',
  productAdmin = '3',
  viewer = '4'
}

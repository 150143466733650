import { NgxPermissionsGuard, NgxPermissionsService } from 'ngx-permissions';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from './services/auth.service';
import { OktaAuthGuard } from '@okta/okta-angular';



export const WPDAuthGuard: CanActivateFn = (route, state) => { 
  const oktaGuard = inject(OktaAuthGuard);
  const authService = inject(AuthService); 
  const ngxGuard = inject(NgxPermissionsGuard);
  const permission = inject(NgxPermissionsService);

  return oktaGuard.canActivate(route, state).then(async (auth: boolean) => {
    if(!auth) {
      return false;
    }
    //... your role guard check code goes here
    const user = await authService.getLoggedOnUser();
    const roles = user?.roles?.map(x=><string><unknown>x)??[];
    permission.loadPermissions(roles);
    return  ngxGuard.canActivate(route, state);
  }); 
};

import { ActivatedRoute } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { BaseUpsert, DialogDataSubmitCallback } from '../common/base-upsert.component';
import { ProductAlternateNameService } from '../services/productAlternateName.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductAlternateNameVM } from '../models/productAlternateNameVM';

@Component({
  selector: 'wpd-product-alternate-name',
  templateUrl: './product-alternate-name.component.html',
  styleUrls: ['./product-alternate-name.component.css']
})
export class ProductAlternateNameComponent extends BaseUpsert<ProductAlternateNameVM> {
  constructor(service: ProductAlternateNameService, route: ActivatedRoute, snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) contextData?: { callback: DialogDataSubmitCallback<ProductAlternateNameVM>; id: BigInteger },
    dialogRef?: MatDialogRef<ProductAlternateNameComponent>
    ) {
    super(service, route, snackBar, contextData, dialogRef);
  }
}
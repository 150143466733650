import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { MatSort, Sort, SortDirection } from "@angular/material/sort";
import { BaseCacheDirective } from './base-cache.directive';

const KEY = 'MAT_SORT';


@Directive({
  selector: '[cvx-sort-cache]'
})
export class SortCacheDirective extends BaseCacheDirective<MatSortData> {

  constructor(private mSort: MatSort, el: ElementRef  ) 
  { super(el, "cvx-sort-cache"); }

  ngOnInit(): void {
    if(this.cvxCache) {
      this.mSort.active = this.cvxCache.active;
      this.mSort.direction = this.cvxCache.direction;
    }
    this.mSort.sortChange.subscribe((sort: Sort) => {
      this.cvxCache = { 
        active: sort.active, 
        direction: sort.direction
      }
    });
  }
}

interface MatSortData {
  active: string;
  direction: SortDirection;
}
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseListComponent } from '../common/base-list.component';
import { ProductAlternateNameService } from '../services/productAlternateName.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'wpd-product-alternate-name-list',
  templateUrl: './product-alternate-name-list.component.html',
  styleUrls: ['./product-alternate-name-list.component.css']
})
export class ProductAlternateNameListComponent extends BaseListComponent<ProductAlternateNameListComponent> {
  displayedColumns: string[] = ['id', 'country', 'product', 'alternateName'];
  constructor(service: ProductAlternateNameService, router: Router, protected snackBar: MatSnackBar) {
    super(service, router, snackBar);
  }
}

<div class="form-horizontal">
    <div class="wpd-loading-shade"
        *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <h3 mat-dialog-title>Port Handling {{data.id}} 
        <button mat-icon-button mat-dialog-close class="float-right" *ngIf="isDialog"><mat-icon>close</mat-icon></button>
    </h3>
    <form #loginForm="ngForm" (ngSubmit)="updateData()" mat-dialog-content>        

        <mat-form-field class="col-md-2">
            <mat-label>Plant No.</mat-label>
            <input matInput name="_PlantNo" [(ngModel)]="data._PlantNo" type="text" readonly />
        </mat-form-field>
        <mat-form-field class="col-md-5">
            <mat-label>Port Name</mat-label>
            <input matInput name="_PortName" [(ngModel)]="data._PortName" type="text" readonly />
        </mat-form-field>
        <mat-form-field class="col-md-5">
            <mat-label>Product Name</mat-label>
            <input matInput name="_ProductName" [(ngModel)]="data._ProductName" type="text" readonly />
        </mat-form-field>
        <div class="row">
            <table class="phTable" aria-describedby="Product list">
                <thead><tr><th class="col-md-2">Package Type</th><th class="col-md-2">Material Num.</th><th class="col-md-2">MOQ</th><th class="col-md-6">Notice</th></tr></thead>
                <tbody>
                    <tr>
                        <td><mat-checkbox name="_Bulk" [(ngModel)]="data._Bulk" [disabled]="true">Bulk</mat-checkbox></td>
                        <td>{{data._BulkProdId}}</td>
                        <td><input matInput name="bulkMOQ" [(ngModel)]="data.bulkMOQ" type="number" [disabled]="!data._Bulk" /></td>
                        <td><input matInput name="bulkNotice" [(ngModel)]="data.bulkNotice" type="text" [disabled]="!data._Bulk"/></td>
                    </tr>
                    <tr>
                        <td><mat-checkbox name="_Tote" [(ngModel)]="data._Tote" [disabled]="true">Tote</mat-checkbox></td>
                        <td>{{data._ToteProdId}}</td>
                        <td><input matInput name="toteMOQ" [(ngModel)]="data.toteMOQ" type="number" [disabled]="!data._Tote"/></td>
                        <td><input matInput name="toteNotice" [(ngModel)]="data.toteNotice" type="text" [disabled]="!data._Tote"/></td>
                    </tr>
                    <tr>
                        <td><mat-checkbox name="_Drum" [(ngModel)]="data._Drum" [disabled]="true">Drum</mat-checkbox></td>
                        <td>{{data._DrumProdId}}</td>
                        <td><input matInput name="drumMOQ" [(ngModel)]="data.drumMOQ" type="number" [disabled]="!data._Drum"/></td>
                        <td><input matInput name="drumNotice" [(ngModel)]="data.drumNotice" type="text" [disabled]="!data._Drum"/></td>
                    </tr>
                    <tr>
                        <td><mat-checkbox name="_Pail" [(ngModel)]="data._Pail" [disabled]="true">Pail</mat-checkbox></td>
                        <td>{{data._PailProdId}}</td>
                        <td><input matInput name="pailMOQ" [(ngModel)]="data.pailMOQ" type="number" [disabled]="!data._Pail"/></td>
                        <td><input matInput name="pailNotice" [(ngModel)]="data.pailNotice" type="text" [disabled]="!data._Pail"/></td>
                    </tr>
                    <tr>
                        <td><mat-checkbox name="_Case" [(ngModel)]="data._Case" [disabled]="true">Case</mat-checkbox></td>
                        <td>{{data._CaseProdId}}</td>
                        <td><input matInput name="caseMOQ" [(ngModel)]="data.caseMOQ" type="number" [disabled]="!data._Case"/></td>
                        <td><input matInput name="caseNotice" [(ngModel)]="data.caseNotice" type="text" [disabled]="!data._Case"/></td>
                    </tr>
                    <tr>
                        <td><mat-checkbox name="barge" [(ngModel)]="data.barge">Barge</mat-checkbox></td>
                        <td></td>
                        <td></td>
                        <td><input matInput name="bargeNotice" [(ngModel)]="data.bargeNotice" type="text" [disabled]="!data.barge"/></td>
                    </tr>                    
                </tbody>
            </table>
        </div>        
        <div class="padding-top-24" *ngIf="data.canUpdate" mat-dialog-actions>
          <div class="col-md-offset-2 col-md-12">
            <div [ngClass]="'alert ' + result.e == null ?'alert-danger' : 'alert-success'"  [innerHTML]="result.e??result.i"></div>
            <button mat-raised-button color="primary" type="submit">Save</button>
          </div>
        </div>
    </form>
</div>


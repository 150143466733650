
<div class="form-horizontal">
    <div class="wpd-loading-shade"
        *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <h3 mat-dialog-title>Product Alternate Name
        <button mat-icon-button mat-dialog-close class="float-right" *ngIf="isDialog"><mat-icon>close</mat-icon></button>
    </h3>
    <form #loginForm="ngForm" (ngSubmit)="updateData()" mat-dialog-content>        
        
        <mat-form-field class="col-md-12">
            <mat-label>Product</mat-label>
            <mat-select  [(value)]="data.productId" name="productId">
                <mat-option *ngFor="let item of data.__Products" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="col-md-12">
            <mat-label>Country</mat-label>
            <mat-select  [(value)]="data.countryId" name="countryId">
                <mat-option *ngFor="let item of data.__Countries" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="col-md-12">
            <mat-label>Alternate Name</mat-label>
            <input matInput name="alternateName" [(ngModel)]="data.alternateName" type="text" />
        </mat-form-field>
        
        <div class="padding-top-24" *ngIf="data.canUpdate" mat-dialog-actions>
          <div class="col-md-offset-2 col-md-12">
            <div [ngClass]="'alert ' + result.e == null ?'alert-danger' : 'alert-success'"  [innerHTML]="result.e??result.i"></div>
            <button mat-raised-button color="primary" type="submit">Save</button>
          </div>
        </div>
    </form>
</div>


<div class="wpd-loading-shade" *ngIf="isLoadingResults">
   <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>
 
<div id="Wrapper" *ngIf="data">
   <div id="LeftCol"></div>
   <div id="portDetailsWrapper">
      <div class="placeHolder">
         <div id="185" class="divPortDetailItem">
            <div *ngxPermissionsOnly="[WPDRole.admin, WPDRole.fuelAdmin, WPDRole.productAdmin, WPDRole.viewer]" class="float-right">
               <a [routerLink]="['/port', id]">View/Edit Port</a>
            </div>
            <h3>{{data.name}}</h3>
            <div class="row">
               <div class="col-sm-2 no-Padding"><b>Notes</b></div>
               <div class="col-sm-10">                        
               <ng-container [ngTemplateOutlet]="portNotesTemplate"
                  [ngTemplateOutletContext]="{notes:data.portNotes?.split('\r\n')}">
               </ng-container>
               </div>
            </div>
            <div class="row">
               <div class="col-sm-2 no-Padding"><b>Standard Notice</b></div>
               <div class="col-sm-10">
                  <p>{{data.daysNotice}}</p>
               </div>
            </div>
            <div class="row">
               <div class="col-sm-2 no-Padding"><b>Contact Name</b></div>
               <div class="col-sm-10">
                  {{data.contactName}}
               </div>
            </div>
            <div class="row" *ngIf="data.contactPhone">
               <div class="col-sm-2 no-Padding"><b>Contact Phone</b></div>
               <div class="col-sm-10">
                  {{data.contactPhone}}
               </div>
            </div>
            <div class="row">
               <div class="col-sm-2 no-Padding"><b>Contact Email</b></div>
               <div class="col-sm-10">
                  {{data.contactEmail}}
               </div>
            </div>             
         </div>
      </div>
   </div>
</div>

<ng-template #portNotesTemplate let-notes="notes">
   <ul>
      <li *ngFor="let item of notes" [ngClass]="{'list-style-none': item?.startsWith('\t')}">{{item}}</li>
   </ul>
</ng-template>
 

import { OktaAuth } from '@okta/okta-auth-js';
import { Component, Inject } from '@angular/core';

import {
  NextPageService,
  NavigationLocation,
  PageLayout,
  SearchType,
  SliderType
} from '@cvx/nextpage';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  SearchType = SearchType;
  SliderType = SliderType;
  constructor(private NP: NextPageService, public authStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, public authService: AuthService) {
    this.NP.options.sitename = 'World Port Directory';    
  }

  logout() {
    this.authService.logout();
  }
}

import { Component, Inject } from '@angular/core';
import { BaseListComponent } from '../common/base-list.component';
import { PortHandlingService } from '../services/portHandling.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogDataSubmitCallback } from '../common/base-upsert.component';
import { PortHandlingList } from '../models/portHandlingVM';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'wpd-port-handling-list',
  templateUrl: './port-handling-list.component.html',
  styleUrls: ['./port-handling-list.component.css']
})
export class PortHandlingListComponent extends BaseListComponent<PortHandlingList> {
  displayedColumns: string[] = ['port', 'product', 'materialNo', 'mOQSet', 'bulk', 'tote', 'drum', 'pail', 'case', 'barge'];
  title: string = '';
  portId: BigInteger;
  productId: BigInteger;
  daId: BigInteger;
  constructor(service: PortHandlingService, router: Router, public route: ActivatedRoute, protected snackBar: MatSnackBar,
  @Inject(MAT_DIALOG_DATA) public contextData?: { callback: DialogDataSubmitCallback<PortHandlingList>; portId: BigInteger; productId: BigInteger, daId: BigInteger },
  dialogRef?: MatDialogRef<PortHandlingListComponent>) {    
    super(service, router, snackBar);
  }
  ngOnInit() {

    this.route.paramMap.subscribe(paramMap => {
      this.portId = <BigInteger><unknown>(paramMap.get('portId'));
      if (!this.portId) {
        this.portId = <BigInteger>(this.contextData?.portId??"0");
      } 
      this.productId = <BigInteger><unknown>(paramMap.get('productId'));
      if (!this.productId) {
        this.productId = <BigInteger>(this.contextData?.productId??"0");
      }
      this.daId = <BigInteger><unknown>(paramMap.get('daId'));
      if (!this.daId) {
        this.daId = <BigInteger>(this.contextData?.daId??"0");
      }  
    });
    if (Number(this.portId) > 0) this.displayedColumns = this.displayedColumns.filter(x=>x != 'port');
    if (Number(this.productId) > 0) this.displayedColumns = this.displayedColumns.filter(x=>x != 'product');
  }

  override getPageFilter(): any { 
    return { PortId: this.portId, ProductId: this.productId, DeliveryAgentId: this.daId };
  };

  override dataLoaded() {
    if(this.data.length == 0) return;
    if (Number(this.portId) > 0)  {
      this.title = " - " + this.data[0].port;
    }
    if (Number(this.productId) > 0)  {
      this.title = " - " + this.data[0].product;
    }
    if (Number(this.daId) > 0)  {
      this.title = " - Delivery Agent: " + this.daId;
    }
  }
}
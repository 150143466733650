import { Component, inject } from '@angular/core';
import { WPDAuthUser, WPDRole } from '../models/wpdAuthUser';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'cvx-base',
  template: `
    <p>
      base list view!
    </p>
  `
})

export class BaseComponent {
  loggedUser: WPDAuthUser | undefined;
  booleanList = [{text: '-', value: null }, {text: 'Yes', value: true }, {text: 'No', value: false }];
  constructor() { this.preLoad() }

  preLoad() {
    const authService = inject(AuthService);
    this.loggedUser = authService.getLoggedOnUserFromSession();
  }

  public get WPDRole(): typeof WPDRole
  {
    return WPDRole;
  }

  public HtmlEncode(input: string) {
    const textArea = document.createElement("textarea");
    textArea.innerText = input;
    return textArea.innerHTML.split("<br>").join("\n");
  }
}



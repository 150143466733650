<!-- Header -->
<np-header>
  <div class="float-right">
    <a *ngIf="!(authStateService.authState$ | async)?.isAuthenticated" routerLink="/login" matTooltip="Login">
      <mat-icon>login</mat-icon>
    </a>
    <a *ngIf="(authStateService.authState$ | async)?.isAuthenticated" routerLink="/profile" matTooltip="View Profile">
      <mat-icon>person</mat-icon>
    </a>
    <a *ngIf="(authStateService.authState$ | async)?.isAuthenticated" (click)="logout()" matTooltip="Logout">
      <mat-icon>logout</mat-icon>
    </a>
  </div>
  <!-- Search -->
  <np-search [searchType]="SearchType.Global"></np-search>
  <!-- Global (top menu) Navigation -->
  <np-menu [location]="NavigationLocation.Global"></np-menu>
</np-header>

<!-- Site-Specific Navigation -->
<np-menu [location]="NavigationLocation.SiteSpecific"></np-menu>


<!-- Main Content -->

<main class="container">
  <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
    <div class="row">
      <div class="col col-12">        
        <router-outlet></router-outlet>
      </div>
    </div>
  </section>
</main>


<!-- Footer -->
<np-footer [contentContactName]="'Sree Natarajan'"
           [contentContactUrl]="'mailto:sree.natarajan@chevron.com'"
           [technicalContactName]="'WFC IT Ebusiness Team'"
           [technicalContactUrl]="'mailto:wfcitebusinessteam@chevron.com'">
</np-footer>

import {  ElementRef } from '@angular/core';

export class BaseCacheDirective<T> {
  KEY = 'CVX_PAGE_CACHE';
  constructor(protected el: ElementRef, private selector: string) {
  }

  get cvxCacheStore(): kv<T>[] {
    return JSON.parse(localStorage.getItem(this.KEY)??"[]");
  }

  get cvxCache(): T {    
    let kv = this.cvxCacheStore.find(kv=>kv.k == this.el.nativeElement.getAttribute(this.selector));
    return (kv != undefined)?kv.v: <T>null;
  }

  set cvxCache(val: T) {
    let store = this.cvxCacheStore;
    let kvIndex = store.findIndex(kv=>kv.k == this.el.nativeElement.getAttribute(this.selector));
    if(kvIndex == -1) {
      store.push({k:this.el.nativeElement.getAttribute(this.selector), v:val});
    }
    else {
      store[kvIndex].v = val;
    }
    localStorage.setItem(this.KEY, JSON.stringify(store));
  }
}

export interface kv<T>{
  k:string;
  v:T;
}
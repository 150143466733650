import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {MatMultiSort, MatMultiSortTableDataSource, TableData} from 'ngx-mat-multi-sort';
import { DummyService } from './demo.service';
import { MatPaginator } from '@angular/material/paginator';
import {animate, state, style, transition, trigger} from '@angular/animations';

export interface SampleDto {
    id: number
    vesselName: string | null;
    iMO: number | null;
    componentName: string | null;
    lubricantName: string | null;
    courierName: string | null;
    wayBilNumber: string | null;
    resultId: string | null;
    sampleStatusId: string | null;
    lab: string | null;
    receptionDT: string | null;
    billingStatus: string | null;
}

export interface TableSort {
  columnName: string | null;
  isAscending: boolean | null;
}
export enum AndOr {
  AND = 1,
  OR = 2
}
export enum FieldType {
  Boolean = 1,
  Decimal = 2,
  Integer = 3,
  Date = 4,
  Text = 5,
  IntegerArray = 6,
  TextArray = 7
}

export enum Option {
  DoesNotBeginWith = 1,
  IsEmpty = 2,
  True = 3,
  DoesNotEndWith = 4,
  IsLessThan = 5,
  BeginsWith = 6,
  IsLessThanOrEqualTo = 7,
  IsEqualTo = 8,
  IsGreaterThanOrEqualTo = 9,
  False = 10,
  IsGreaterThan = 11,
  Contains = 12,
  EndsWith = 13,
  IsNotEqualTo = 14,
  DoesNotContain = 15,
  IsNotEmpty = 16
}

export interface AdvancedFilter {
  andOrId: AndOr;
  fieldName: string | null;
  fieldTypeId: FieldType;
  fieldOptionId: Option;
  searchValue: string | null;
  placeholder?: string | null;
  subFilters?: AdvancedFilter[] | null;
}

export interface TableXFilter {
    quickFilter: string | null;
    filters: AdvancedFilter[] | null;
    pageSize: number | null;
    currentPageNumber: number | null;
    sort: TableSort[] | null;
    cols: string[] | null;
}

export interface ColumnSetting {
  id: string;
  name: string;
  mapping?: string;
  isActive?: boolean;
}

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))]),    
  ]
})
export class DemoComponent{

  @ViewChild(MatMultiSort) sort: MatMultiSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  table: TableData<SampleDto>;
  hiddenColumns: { id: string; name: string; }[] = [];
  filter: TableXFilter = <TableXFilter>{};
  expandedElement: SampleDto | null;
  colSettings: ColumnSetting[] = [
    { id: 'id', name: 'Sample Id' },
    { id: 'vesselName', name: 'Vessel Name' },
    { id: 'componentName', name: 'Component' },
    { id: 'lubricantName', name: 'Product' },
    { id: 'resultId', name: 'Result' },
    { id: 'sampleStatusId', name: 'Status' },
    { id: 'receptionDT', name: 'Reception Date' },
    { id: 'imo', name: 'IMO', mapping:"IMO", isActive: false },
    { id: 'courierName', name: 'Courier', isActive: false },
    { id: 'wayBilNumber', name: 'Way-Bill', isActive: false },
    { id: 'lab', name: 'Lab', isActive: false },
    { id: 'billingStatus', name: 'BillingStatus', isActive: false}
  ];  

  constructor(
    private dummyService: DummyService,
    private changeDetectorRef: ChangeDetectorRef
  ) {  

    this.table = new TableData<SampleDto>(
      this.colSettings, { localStorageKey: 'settings' }
    );
    this.table.displayedColumns = this.table.columns.filter(col=>col.isActive??true).map(col => col.id);
  }

  ngOnInit() {

    this.table.nextObservable.subscribe(() => { this.getData(); });
    this.table.sortObservable.subscribe(() => { this.getData(); });
    this.table.previousObservable.subscribe(() => { this.getData(); });
    this.table.sizeObservable.subscribe(() => { this.getData(); });
    this.changeDetectorRef.detectChanges();

    setTimeout(() => {
      this.initData();
    }, 0);
  }

  initData() {    
    this.table.pageSize = this.paginator.pageSize;
    this.table.pageIndex = this.paginator.pageIndex;
    this.table.dataSource = new MatMultiSortTableDataSource(this.sort);
    if((this.sort?.actives?.length??0) == 0) {
      this.getData();
    }
  }

  getMapping(id: string): string {
    let col = this.colSettings.find(c=>c.id == id);
    if(col == undefined) return "";
    if (col.mapping){
      return col.mapping
    }    
    return col.id.charAt(0).toUpperCase() + col.id.slice(1);
  }

  getData() {
    this.filter.quickFilter = "";
    this.filter.currentPageNumber = this.paginator.pageIndex;
    this.filter.pageSize = this.paginator.pageSize;
    this.filter.sort = [];
    //this.filter.cols = this.colSettings.filter(col=>this.table.displayedColumns.indexOf(col.id) > -1).map(col => this.getMappingColumn(col));
    this.filter.cols = this.table.columns.map(col => this.getMapping(col.id));

    for(let i=0; i<this.table.sortParams.length; i++){
      this.filter.sort.push({"columnName": this.getMapping(this.table.sortParams[i]), "isAscending": this.table.sortDirs[i] == 'asc'});
    }
    
    this.filter.filters = [];

    this.filter.filters.push({ 
      andOrId: AndOr.AND, 
      fieldTypeId: FieldType.Text,
      fieldName: "ResultId",      
      fieldOptionId: Option.IsEqualTo,
      searchValue: "red"
    });
    this.filter.filters.push({
      andOrId: AndOr.OR, 
      fieldTypeId: FieldType.Text,
      fieldName: "ResultId",      
      fieldOptionId: Option.IsEqualTo,
      searchValue: "Amber"  
    });

    this.dummyService.getList(this.filter).subscribe(res => {
      this.table.totalElements = res.data.totalCount;
      this.table.data = res.data.items;
      if(this.table.displayedColumns.indexOf("expand")==-1) {
        this.table.displayedColumns.push("expand");
      }
      this.hiddenColumns = this.table.columns.filter(col=>(col.isActive??true) == false);
    });

  }
  

}

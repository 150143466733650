<h1>Product Alternate Names</h1>
<div class="mat-elevation-z8">
  <div *ngxPermissionsOnly="[ WPDRole.admin, WPDRole.portAdmin, WPDRole.productAdmin ]">
    <button mat-fab matTooltip="Excel Download"  (click)="downloadExcelFile()" class="excel-download"><mat-icon>download</mat-icon></button>
    <span *ngxPermissionsOnly="[ WPDRole.admin ]">
      <button mat-fab matTooltip="Excel Upload" (click)="fileInput.click()" class="excel-upload"><mat-icon>upload</mat-icon></button>
      <input #fileInput hidden="hidden" type="file" onclick="this.value=null" (change)="uploadFile($event)" accept=".xlsx"/>
    </span>
  </div>
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput placeholder="Ex. Japan" [formControl]="searchKeywordFilter" cvx-input-cache="ProductAlt-List-QSearch">
  </mat-form-field>

  <div class="wpd-loading-shade"
       *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div>
    <table mat-table [dataSource]="data" matSort matSortActive="id" matSortDirection="asc" aria-describedby="Product alternate name list">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let row"><a [routerLink]="['/altName', row.id]">{{row.id}}</a></td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
        <td mat-cell *matCellDef="let row">{{row.country}}</td>
      </ng-container>

      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
        <td mat-cell *matCellDef="let row">{{row.product}}</td>
      </ng-container>

      <ng-container matColumnDef="alternateName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Alternate Name</th>
        <td mat-cell *matCellDef="let row">{{row.alternateName}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openRecord(['/altName', row.id])"></tr>      

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data matching the filter "{{tableFilter.quickFilter}}"</td>
      </tr>
    </table>
  </div>

  <mat-paginator [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 30, 50, resultsLength]" [showFirstLastButtons]="true"></mat-paginator>
</div>

<div class="mat-elevation-z8" style="padding: 8px;">
    <mat-multi-sort-table-settings [tableData]="table" sortToolTip="Change sort order"
                                   [closeDialogOnChoice]="false">
      <button mat-icon-button matTooltip="Pick columns"><mat-icon>settings</mat-icon></button>
      
      <ng-template #sortIndicator let-direction='direction' let-columnName='columnName'>
        {{columnName}}
        <mat-icon class="sort-icon-mat-table" [matTooltip]="'Change sort order'" *ngIf="direction">{{direction === 'asc' ? 'arrow_upward' : 'arrow_downward'}}</mat-icon>
      </ng-template>
    </mat-multi-sort-table-settings>
    <table mat-table [dataSource]="table.dataSource" matMultiSort (matSortChange)="table.onSortEvent()" multiTemplateDataRows>
  
      <!-- Create all your columns with *ngfor, this is the lazy way out and only works if the display of the data does not differ -->
      <ng-container *ngFor="let column of table.columns" [matColumnDef]="column.id">
        <th mat-header-cell *matHeaderCellDef [mat-multi-sort-header]="column.id"> {{column.name}} </th>
        <td mat-cell *matCellDef="let row"> {{row[column.id]}} </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">           
              <mat-icon>{{expandedElement === element ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>              
          </button>
        </td>       
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="table.columns.length">
          <div class="example-element-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="row">
            <div *ngFor="let column of hiddenColumns" class="col-2">
              <div><b>{{column.name}}</b></div>
              <div>{{element[column.id]}}</div>
            </div>
          </div>
        </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="table.displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: table.displayedColumns;"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      
    </table>

    <mat-paginator [pageSize]="table.pageSize" [pageSizeOptions]="table.pageSizeOptions" [showFirstLastButtons]="true"
                   [length]="table.totalElements ? table.totalElements : 0" (page)="table.onPaginationEvent($event)">
    </mat-paginator>
  </div>
  
import { Component } from '@angular/core';
import { BaseListComponent } from '../common/base-list.component';
import { ProductList } from '../models/productVM';
import { ProductService } from '../services/product.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'wpd-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent extends BaseListComponent<ProductList> {
  displayedColumns: string[] = ['id', 'name', 'sapCode', 'mpId', 'category', 'productClass', 'productType', 'isActive', 'isSynthetics', 'isOEMApproved','portCount'];
  fIsActive = new FormControl();
  fIsSynthetic = new FormControl();
  fIsOEM = new FormControl();
  fPortExist = new FormControl(true);
  constructor(service: ProductService, router: Router, protected snackBar: MatSnackBar) {
    super(service, router, snackBar);
  }

  override getPageFilter(): any { 
    return { fIsActive: this.fIsActive.value, fIsSynthetic: this.fIsSynthetic.value, fIsOEM: this.fIsOEM.value, fPortExist: this.fPortExist.value };
  };
}

<h1>Countries</h1>
<div class="mat-elevation-z8">
  <div *ngxPermissionsOnly="[ WPDRole.admin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.fuelAdmin ]">
    <button mat-fab matTooltip="Excel Download"  (click)="downloadExcelFile()" class="excel-download"><mat-icon>download</mat-icon></button>
    <span *ngxPermissionsOnly="[ WPDRole.admin ]">
      <button mat-fab matTooltip="Excel Upload" (click)="fileInput.click()" class="excel-upload"><mat-icon>upload</mat-icon></button>
      <input #fileInput hidden="hidden" type="file" onclick="this.value=null" (change)="uploadFile($event)" accept=".xlsx"/>
    </span>
  </div>
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput placeholder="Ex. Belgium" [formControl]="searchKeywordFilter" cvx-input-cache="Country-List-QSearch">
  </mat-form-field>

  <div class="wpd-loading-shade"
       *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div>
    <table mat-table [dataSource]="data" matSort matSortActive="id" matSortDirection="asc" aria-describedby="Country list">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let row"><a [routerLink]="['/country', row.id]">{{row.id}}</a></td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row"><a [routerLink]="['/country', row.id]">{{row.name}}</a></td>
      </ng-container>

      <!-- Region Column -->
      <ng-container matColumnDef="region">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
        <td mat-cell *matCellDef="let row">{{row.region}}</td>
      </ng-container>

      <!-- Ports Count -->
      <ng-container matColumnDef="ports">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field style="width: 130px;">
            <mat-label>Ports?</mat-label>
            <mat-select [formControl]="fPortExist" name="fPortExist" (selectionChange)="filterChange()" cvx-select-cache="Country-List-PortExist">
              <mat-option *ngFor="let item of booleanList" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>            
          </mat-form-field>
          <span mat-sort-header style="position: absolute"><mat-icon aria-hidden="false" fontIcon="swap_vert"></mat-icon></span>
        </th>
        <td mat-cell *matCellDef="let row">{{row.ports}}</td>
      </ng-container>

      <!-- Is Active -->
      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field style="width: 110px;">
            <mat-label>Active?</mat-label>
            <mat-select [formControl]="fIsActive" name="fIsActive" (selectionChange)="filterChange()" cvx-select-cache="Country-List-Active">
              <mat-option *ngFor="let item of booleanList" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>            
          </mat-form-field>
          <span mat-sort-header style="position: absolute"><mat-icon aria-hidden="false" fontIcon="swap_vert"></mat-icon></span>
        </th>
        <td mat-cell *matCellDef="let row"> <mat-icon aria-hidden="false" fontIcon="{{row.isActive ? 'check': 'close'}}" class="text-{{row.isActive ? 'success': 'danger'}}"></mat-icon></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openRecord(['/country', row.id])"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data matching the filter "{{tableFilter.quickFilter}}"</td>
      </tr>
    </table>
  </div>

  <mat-paginator [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 30, 50, resultsLength]" [showFirstLastButtons]="true"></mat-paginator>
</div>

<div class="wpd-loading-shade" *ngIf="isLoadingResults">
   <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<div id="Wrapper" *ngIf="data">
   <div id="LeftCol"></div>
   <div id="portDetailsWrapper">
      <div class="placeHolder">
         <div id="185" class="divPortDetailItem">
            <div *ngxPermissionsOnly="[WPDRole.admin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.viewer]" class="float-right">
               <a [routerLink]="['/port', id]">View/Edit Port</a>
            </div>
            <h3><img *ngIf="data.isHallmark" alt="Hallmark" title="Hallmark" src="/assets/images/marker-yellow-1.png" style="padding-right: 10px" />{{data.name}}</h3>
            <div *ngIf="false" class="row">
               <div class="col-sm-2 no-Padding"><span class="font-bold">Product Categories</span></div>
               <div class="col-sm-10 no-Padding">
                  <div class="row">
                     <div class="col-sm-4" *ngFor="let cat of categories">{{cat}}</div>
                  </div>
               </div>
            </div>
            <p></p>
            <div class="row" *ngIf="data.minimumQuantity?.length">
               <div class="col-sm-2 no-Padding">
                  <span class="font-bold">Minimum Quantity</span>
               </div>
               <div class="col-sm-10">
                  <table class="tbl-minimum-quantity col-md-10" aria-describedby="Minimum order quantity">
                     <tr>
                        <th scope="col">&nbsp;</th>
                        <th scope="col">Barge/Launch</th>
                        <th scope="col">Truck</th>
                     </tr>
                     <tr *ngFor="let item of data.minimumQuantity;">
                        <th scope="row" class="col-md-2">{{item.header}}</th>
                        <td class="col-md-4" [attr.colspan]="item.bl === item.truck ? 2 : 0">{{item.bl}}</td>
                        <td class="col-md-4" *ngIf="item.bl !== item.truck">{{item.truck}}</td>
                     </tr>
                     <tfoot *ngIf="footNotes?.length">
                        <tr>
                           <td colspan="3">
                              <div *ngFor="let item of footNotes;" style="font-style: italic; font-size: smaller; display: block;">
                                 {{item}}
                              </div>
                           </td>
                        </tr>
                     </tfoot>
                  </table>                  
               </div>
            </div>
            <div class="row">
               <div class="col-sm-2 no-Padding"><span class="font-bold">Standard Notice</span></div>
               <div class="col-sm-10">
                  <p>{{data._notice.tooltip}}</p>
               </div>
            </div>
            <div class="row">
               <div class="col-sm-2 no-Padding"><span class="font-bold">Port Notes</span></div>
               <div class="col-sm-10">
                  <ul>
                     <li *ngIf="data.additionalLeadTime?.length??0 > 0"><b>Additional Lead Time</b>
                        <div [innerHTML]="additionalLeadTime"></div>
                     </li>
                     <li *ngIf="data.pumpingAvailability?.length??0 > 0"><b>Pumping from Packages Available</b>
                        <div [innerHTML]="pumpingAvailability"></div>
                     </li>
                     <li *ngIf="data.additionalCharges?.length??0 > 0"><b>Additional Charges</b>
                        <div [innerHTML]="additionalCharges"></div>
                     </li>
                     <li *ngIf="data.otherInformation?.length??0 > 0"><b>Other Information</b>
                        <div [innerHTML]="otherInformation"></div>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="portLegend row">
               <div>
                  Standard Notice
                  <div class="badge-noText" matTooltip="{{data._notice.tooltip}}"></div>
               </div>
               <div>
                  Non-standard Notice&nbsp;
                  <div class="badge" matTooltip="X working days">X</div>
               </div>
               <div>
                  More Information
                  <div class="badge-info" matTooltip="More information"></div>
               </div>
            </div>

            <div class="table-responsive mat-elevation-z8">
               <mat-form-field>
                  <mat-label>Filter</mat-label>
                  <input matInput placeholder="Ex. Category or Product Name" [formControl]="searchKeywordFilter"
                     (keyup)="applyFilter()">
               </mat-form-field>

               <div>
                  <table mat-table [dataSource]="tableData" matSort matSortActive="cat" matSortDirection="asc" aria-describedby="Product list">

                     <ng-container matColumnDef="cat">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Product Category</th>
                        <td mat-cell *matCellDef="let row">{{row.cat}}</td>
                     </ng-container>

                     <ng-container matColumnDef="prd">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Product Name</th>
                        <td mat-cell *matCellDef="let row">
                           <a *ngIf="row.pds" [href]="row.pds" matTooltip="Link to Product Data Sheet">{{row.prd}}</a>
                           <span *ngIf="!row.pds">{{row.prd}}</span>
                           <div *ngIf="row.ln" style="color:red; font-size:11px;font-style:italic">{{row.ln}}</div>
                        </td>
                     </ng-container>

                     <ng-container matColumnDef="b">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bulk</th>
                        <td mat-cell *matCellDef="let row">
                           <div [class]="row._b.class" matTooltip="{{row._b.tooltip}}">{{row._b.text}}</div>
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="t">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tote/IBC</th>
                        <td mat-cell *matCellDef="let row">
                           <div [class]="row._t.class" matTooltip="{{row._t.tooltip}}">{{row._t.text}}</div>
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="d">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Drum</th>
                        <td mat-cell *matCellDef="let row">
                           <div [class]="row._d.class" matTooltip="{{row._d.tooltip}}">{{row._d.text}}</div>
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="p">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pail</th>
                        <td mat-cell *matCellDef="let row">
                           <div [class]="row._p.class" matTooltip="{{row._p.tooltip}}">{{row._p.text}}</div>
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="c">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Case</th>
                        <td mat-cell *matCellDef="let row">
                           <div [class]="row._c.class" matTooltip="{{row._c.tooltip}}">{{row._c.text}}</div>
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="br">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Barge</th>
                        <td mat-cell *matCellDef="let row">
                           <div [class]="row._br.class" matTooltip="{{row._br.tooltip}}">{{row._br.text}}</div>
                        </td>
                     </ng-container>

                     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                     <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                     <!-- Row shown when there is no matching data. -->
                     <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data matching the filter
                           "{{searchKeywordFilter.value}}"</td>
                     </tr>
                  </table>
               </div>
            </div>
         </div>

      </div>
   </div>
</div>
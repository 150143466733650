import { environment } from 'src/environments/environment';

import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { provideHttpClient, HTTP_INTERCEPTORS, withInterceptorsFromDi } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMapsModule } from '@angular/google-maps'
import { FileSaverModule } from 'ngx-filesaver';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';

import { ErrorCatchingInterceptor } from './interceptors/error-catching.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { NullDefaultValueDirective } from './directives/input-null-value.directive';
import { AuthService } from './services/auth.service';

import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_CONFIG,  OktaAuthModule } from '@okta/okta-angular';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCardModule} from '@angular/material/card';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';

import { NextPage } from '@cvx/nextpage';


import { CountryComponent } from './country/country.component';
import { CountryListComponent } from './country-list/country-list.component';
import { AppErrorHandler } from './common/app-error-handler';
import { PortService } from './services/port.service';
import { CountryService } from './services/country.service';
import { DataService } from './services/data.service';
import { DeliveryAgentService } from './services/deliveryAgent.service';
import { PortComponent } from './port/port.component';
import { PortListComponent } from './port-list/port-list.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { WpdMapComponent } from './wpdmap/wpdmap.component';
import { MapService } from './services/map.service';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductComponent } from './product/product.component';
import { ProductAlternateNameListComponent } from './product-alternate-name-list/product-alternate-name-list.component';
import { ProductAlternateNameComponent } from './product-alternate-name/product-alternate-name.component';
import { ProductService } from './services/product.service';
import { ProductAlternateNameService } from './services/productAlternateName.service';
import { PortDetailComponent } from './port-detail/port-detail.component';
import { PortHandlingComponent } from './port-handling/port-handling.component';
import { PortHandlingListComponent } from './port-handling-list/port-handling-list.component';
import { PortHandlingService } from './services/portHandling.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { BaseService } from './services/base.service';
import { OutSystemService } from './services/os.service';
import { DeliveryAgentComponent } from './delivery-agent/delivery-agent.component';
import { DeliveryAgentListComponent } from './delivery-agent-list/delivery-agent-list.component';
import { FuelMapComponent } from './fuel-map/fuel-map.component';
import { FuelPortComponent } from './fuel-port/fuel-port.component';
import { SortCacheDirective } from './directives/cache-directives/sort-cache.directive';
import { InputCacheDirective } from './directives/cache-directives/input-cache.directive';
import { SelectCacheDirective } from './directives/cache-directives/select-cache.directive';
import { DemoComponent } from './demo/demo.component';
import { MatMultiSortModule } from 'ngx-mat-multi-sort';
import { DummyService } from './demo/demo.service';

const componentList = [ 
  // Next Page 
  NextPage,

  // CDK
  BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
  OktaAuthModule,
  FormsModule, ReactiveFormsModule,
  GoogleMapsModule,
  NgSelectModule,
  NgOptionHighlightModule,
  FileSaverModule,
  NgxPermissionsModule.forRoot(),
  

  // Local
  AppRoutingModule,

  // Material
  MatSlideToggleModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatIconModule,
  MatSelectModule,
  MatOptionModule,
  MatCheckboxModule,
  MatButtonModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatDialogModule,
  MatTabsModule,
  MatRadioModule,
  MatExpansionModule,
  MatCardModule,
  MatButtonToggleModule,
  MatMultiSortModule  
];

@NgModule({
  declarations: [
    NullDefaultValueDirective,
    SortCacheDirective,
    InputCacheDirective,
    SelectCacheDirective,

    AppComponent,
    HomeComponent,
    CountryComponent,
    CountryListComponent,
    PortComponent,
    PortListComponent,
    LoginComponent,
    ProfileComponent,
    WpdMapComponent,
    ProductListComponent,
    ProductComponent,
    ProductAlternateNameListComponent,
    ProductAlternateNameComponent,
    PortDetailComponent,
    PortHandlingComponent,
    PortHandlingListComponent,
    UnauthorizedComponent,
    DeliveryAgentComponent,
    DeliveryAgentListComponent,
    FuelMapComponent,
    FuelPortComponent,
    DemoComponent    
  ],
  imports: [
    BrowserAnimationsModule,
    componentList    
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { 
      provide: OKTA_CONFIG, 
      useFactory: () => {
        const oktaAuth = new OktaAuth(environment.oidc);
        return {
          oktaAuth,
          onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
            const triggerLogin = async () => {              
              // Redirect the user to your custom login page                        
              const router = injector.get(Router);
              router.navigate(['/login']);
            };
            if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
              // App initialization stage
              triggerLogin();
            } else {
              // Ask the user to trigger the login process during token autoRenew process
              if (confirm("Do you want to re-authenticate?"))
              {
                triggerLogin();
              }
            }
          }  
        }
      }
    },
    NgxPermissionsService,
    AuthService,
    BaseService,
    DataService,
    OutSystemService,
    PortService,
    CountryService,
    DeliveryAgentService,
    MapService,
    ProductService,
    ProductAlternateNameService,
    PortHandlingService,
    WpdMapComponent,
    DummyService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) { }  
}

<h1>Ports</h1>
<div class="mat-elevation-z8">
  <div *ngxPermissionsOnly="[ WPDRole.admin, WPDRole.portAdmin, WPDRole.productAdmin, WPDRole.fuelAdmin ]">
    <button mat-fab matTooltip="Excel Download"  (click)="downloadExcelFile()" class="excel-download"><mat-icon>download</mat-icon></button>
    <span *ngxPermissionsOnly="[ WPDRole.admin ]">
      <button mat-fab matTooltip="Excel Upload" (click)="fileInput.click()" class="excel-upload"><mat-icon>upload</mat-icon></button>
      <input #fileInput hidden="hidden" type="file" onclick="this.value=null" (change)="uploadFile($event)" accept=".xlsx"/>
    </span>
  </div>
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput placeholder="Ex. Houston" [formControl]="searchKeywordFilter" cvx-input-cache="Port-List-QSearch">
  </mat-form-field>

  <div class="wpd-loading-shade"
       *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div>
    <table mat-table [dataSource]="data" matSort matSortActive="id" matSortDirection="asc" aria-describedby="Port list">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let row">
          <a [routerLink]="['/portHandling', row.id, null, null]" class="phLink" matTooltip="Port Handling"><mat-icon>link</mat-icon></a>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row"><a [routerLink]="['/port', row.id]">{{row.name}}</a></td>
      </ng-container>

      <!-- Plant Column -->
      <ng-container matColumnDef="plant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plant</th>
        <td mat-cell *matCellDef="let row">{{row.plant}}</td>
      </ng-container>

      <!-- Region Column -->
      <ng-container matColumnDef="region">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
        <td mat-cell *matCellDef="let row">{{row.region}}</td>
      </ng-container>

      <!-- Country Column -->
      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
        <td mat-cell *matCellDef="let row">{{row.country}}</td>
      </ng-container>

      <!-- Products Count Column -->
      <ng-container matColumnDef="products">
        <th mat-header-cell class="text-end" *matHeaderCellDef>
          <mat-form-field style="width: 130px;">
            <mat-label>Products?</mat-label>
            <mat-select [formControl]="fProductExist" name="fProductExist" (selectionChange)="filterChange()" cvx-select-cache="Port-List-Prd-Exist">
              <mat-option *ngFor="let item of booleanList" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>
          </mat-form-field>
          <span mat-sort-header style="position: absolute"><mat-icon aria-hidden="false" fontIcon="swap_vert"></mat-icon></span>
        </th>
        <td mat-cell class="text-end" *matCellDef="let row">{{row.products}}</td>
      </ng-container>

      <!-- IsActive Column -->
      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field style="width: 110px;">
            <mat-label>Active?</mat-label>
            <mat-select [formControl]="fIsActive" name="fIsActive" (selectionChange)="filterChange()" cvx-select-cache="Port-List-Port-IsActive">
              <mat-option *ngFor="let item of booleanList" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>            
          </mat-form-field>
          <span mat-sort-header style="position: absolute"><mat-icon aria-hidden="false" fontIcon="swap_vert"></mat-icon></span>
        </th>
        <td mat-cell class="text-center" *matCellDef="let row"> <mat-icon aria-hidden="false" fontIcon="{{row.isActive ? 'check': 'close'}}" class="text-{{row.isActive ? 'success': 'danger'}}"></mat-icon></td>
      </ng-container>

      <!-- IsHallmark Column -->
      <ng-container matColumnDef="isHallmark">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field style="width: 110px;">
            <mat-label>Hallmark?</mat-label>
            <mat-select [formControl]="fIsHallmark" name="fIsHallmark" (selectionChange)="filterChange()" cvx-select-cache="Port-List-IsHallmark">
              <mat-option *ngFor="let item of booleanList" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>
          </mat-form-field>
          <span mat-sort-header style="position: absolute"><mat-icon aria-hidden="false" fontIcon="swap_vert"></mat-icon></span>
        </th>
        <td mat-cell class="text-center" *matCellDef="let row"> <mat-icon aria-hidden="false" fontIcon="{{row.isHallmark ? 'turned_in_not': 'close'}}" class="text-{{row.isHallmark ? 'warning': 'danger'}}"></mat-icon></td>
      </ng-container>

      <!-- IsShipyard Column -->
      <ng-container matColumnDef="isShipyard">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field style="width: 110px;">
            <mat-label>Shipyard?</mat-label>
            <mat-select [formControl]="fIsShipyard" name="fIsShipyard" (selectionChange)="filterChange()" cvx-select-cache="Port-List-IsShipyard">
              <mat-option *ngFor="let item of booleanList" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>
          </mat-form-field>
          <span mat-sort-header style="position: absolute"><mat-icon aria-hidden="false" fontIcon="swap_vert"></mat-icon></span>
        </th>
        <td mat-cell class="text-center" *matCellDef="let row"> <mat-icon aria-hidden="false" fontIcon="{{row.isShipyard ? 'directions_boat': 'close'}}" class="text-{{row.isShipyard ? 'success': 'danger'}}"></mat-icon></td>
      </ng-container>

      <!-- Lat/Lng Column -->
      <ng-container matColumnDef="lat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon aria-hidden="false" fontIcon="{{(row.lat && row.lng)? 'location_on': 'close'}}" class="text-{{(row.lat && row.lng)? 'success': 'danger'}}" [matTooltip]="'Lat:' + row.lat + '\n Lng:' + row.lng"></mat-icon></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" cvx-sort-cache="Port-List"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openRecord(['/port', row.id])"></tr>
      

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data matching the filter "{{tableFilter.quickFilter}}"</td>
      </tr>
    </table>
  </div>

  <mat-paginator [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 30, 50, resultsLength]" [showFirstLastButtons]="true"></mat-paginator>
</div>

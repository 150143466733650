import { Component, Inject } from '@angular/core';
import { BaseUpsert, DialogDataSubmitCallback } from '../common/base-upsert.component';
import { PortHandlingVM } from '../models/portHandlingVM';
import { PortHandlingService } from '../services/portHandling.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'wpd-port-handling',
  templateUrl: './port-handling.component.html',
  styleUrls: ['./port-handling.component.css']
})
export class PortHandlingComponent extends BaseUpsert<PortHandlingVM> {
  constructor(service: PortHandlingService, route: ActivatedRoute, snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) contextData?: { callback: DialogDataSubmitCallback<PortHandlingVM>; id: BigInteger },
    dialogRef?: MatDialogRef<PortHandlingComponent>
    ) {
    super(service, route, snackBar, contextData, dialogRef);
  }
}

<h1 class="ui header">
 User Profile
</h1>
<table class="ui table" aria-describedby="Profile">
  <thead>
    <tr>
      <th>Title</th>
      <th>Value</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let claim of claims">
      <td>{{claim.claim}}</td>
      <td id="claim-{{claim.claim}}">{{claim.value}}</td>
    </tr>
  </tbody>
</table>
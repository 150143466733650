import {Injectable, Inject} from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TableFilter } from '../models/table-filter';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class DataService extends BaseService {    

    constructor(protected http: HttpClient, @Inject('BASE_URL') protected baseUrl: string, @Inject('controller')public controller: string) {
        super();
    }
  
    getList(filter: TableFilter): Observable<any> {
       return this.http.post(environment.serviceURL + "api/" + this.controller + '/GetList', filter).pipe(
        catchError(this.handleError)
       );
    }

    getById(id: BigInteger): Observable<any> {
        return this.http.get(environment.serviceURL+ "api/" + this.controller + '/GetById?Id=' + id).pipe(
         catchError(this.handleError)
        );
    }

    getSelectList(): Observable<any> {
        return this.http.get(environment.serviceURL + "api/" + this.controller + '/GetSelectList').pipe(
            catchError(this.handleError)
           );
    }

    update(resource: any): Observable<any> {
        return this.http.post(environment.serviceURL + "api/" + this.controller + '/Edit', resource).pipe(
            catchError(this.handleError)
           );
    }

    delete(id: BigInteger): Observable<any> {
        return this.http.delete(environment.serviceURL+ this.controller + '/Delete?Id=' + id).pipe(
         catchError(this.handleError)
        );
    }

    downloadExcel(filter: TableFilter, isExcel: boolean = true) {
        return this.http.post(environment.serviceURL + "api/" + this.controller + '/DownloadExcel', filter, 
            {params: {'isExcel': isExcel}, responseType: 'blob', observe: 'response'}).pipe(catchError(this.handleError));
    }

    uploadExcel(fileToUpload: File) {
        const formData = new FormData();
        formData.append('FileUpload', fileToUpload);
        return this.http.post(environment.serviceURL + "api/" + this.controller + '/UploadExcel', formData).pipe(
            catchError(this.handleError)
           );
    }



   
}

import { Directive, ElementRef, ChangeDetectorRef, AfterViewInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { DefaultValueAccessor, NgControl, NgModel, SelectControlValueAccessor } from '@angular/forms';
import { BaseCacheDirective } from './base-cache.directive';
import { MatSelect } from '@angular/material/select';



@Directive({
    selector: '[cvx-select-cache] [formControl]',
    providers: [DefaultValueAccessor]
})

export class SelectCacheDirective extends BaseCacheDirective<any>{
  constructor(el: ElementRef, private matSelect: MatSelect, private acc: DefaultValueAccessor) {
    super(el, "cvx-select-cache")
  }


  ngOnInit(): void {
    if(this.cvxCache != null) {
      this.matSelect.value = this.cvxCache;
      this.acc.onChange(this.cvxCache);
    }
    
    this.matSelect.selectionChange?.subscribe((value: any)=> {
      this.cvxCache = value.value;
    });
  }
}
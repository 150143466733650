import { Component, Inject } from '@angular/core';
import { BaseUpsert } from '../common/base-upsert.component';
import { DeliveryAgentService } from '../services/deliveryAgent.service';
import { DeliveryAgentVM } from '../models/deliveryAgentVM';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
export type DialogDataSubmitCallback<T> = (row: T) => void;

@Component({
  selector: 'app-delivery-agent',
  templateUrl: './delivery-agent.component.html',
  styleUrls: ['./delivery-agent.component.css']
})
export class DeliveryAgentComponent extends BaseUpsert<DeliveryAgentVM> {
  osURL: string;
  constructor(service: DeliveryAgentService, route: ActivatedRoute, snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) contextData?: { callback: DialogDataSubmitCallback<DeliveryAgentVM>; id: BigInteger },
    dialogRef?: MatDialogRef<DeliveryAgentComponent>
  ) {
    super(service, route, snackBar, contextData, dialogRef);
    this.osURL = environment.osURL;
  }
}

import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { MatTableDataSource } from '@angular/material/table';
import { APIResultVM } from '../models/apiResultVM';
import { ClusterIconStyle, GoogleMap, MapMarker } from '@angular/google-maps';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { MapService } from '../services/map.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fuel-map',
  templateUrl: './fuel-map.component.html',
  styleUrls: ['./fuel-map.component.css']
})
export class FuelMapComponent extends BaseComponent {
  zoom = 2;
  center: google.maps.LatLngLiteral = { lat: 23.3, lng:13.58 };
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    maxZoom: 10,
    minZoom: 2,
    streetViewControl: false,
    mapTypeControl: false,
    scaleControl: true,
    zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM }
  };

  markers : any[] = [];
  tableData: MatTableDataSource<any>;
  mapData : APIResultVM<any[]>;
  isLoadingResults = false;
  currPort: any = {};  
  clusterIconStyle: ClusterIconStyle =  {url: this.baseUrl + 'assets/images/m1.png',  width: 28,  height: 28,  textColor: '#000000', className:'wpd-cluster', anchorIcon: [0,0]};
  chevronImage = this.baseUrl + "assets/images/marker-blue-1.png";


  markerNotes: any;
  displayedColumns: string[] = ['name','portNotes','daysNotice','contactEmail','contactName'];
  searchKeywordFilter = new FormControl(); 
  

  @ViewChild('gMap', { static: false }) gMap: GoogleMap;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;  

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth, protected service: MapService, 
  protected snackBar: MatSnackBar, public dialog: MatDialog, public authService: AuthService,
  public router: Router, @Inject('BASE_URL') protected baseUrl: string
  ) {
    super();
  }
 
  ngAfterViewInit() {    
    this.loadMarkers();
  } 
 
  loadMarkers() {

    if((localStorage.getItem("fuelMapData")??"")!="") {
      this.mapData = JSON.parse(localStorage.getItem("fuelMapData")??"");
      this.loadMarkersInMap();
      this.validateCacheKey();
      return;
    }
    this.isLoadingResults = true;   
    this.service.getFuelPorts().subscribe(
    { 
      next: (data: APIResultVM<any[]>) => {        
        if (data.e != null) {
          this.snackBar.open(this.mapData.e ?? "", "Error");
        }
        else if (data.i != null) {
          this.snackBar.open(this.mapData.i ?? "Some info", "Info", { duration: 2 * 1000 });
        }
        else {
          this.mapData = data;
          localStorage.setItem("fuelMapData",JSON.stringify(data));          
          this.loadMarkersInMap();
        }
      },      
      error: (e) => { 
        this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); 
        console.log(e);
        this.isLoadingResults = false;
      },
      complete: () => { this.isLoadingResults = false; }, 

    });
  }

  validateCacheKey()
  {
    const key = localStorage.getItem("wpdCacheKey");

    this.service.validateCacheKey(key??"").subscribe({          
      next: (data: APIResultVM<any[]>) => {
        localStorage.setItem("wpdCacheKey", data.k??"");
        if (data.e != null) {
          this.snackBar.open(data.e ?? "", "Error");
        }
        else if (data.i != null) {
          this.snackBar.open(data.i ?? "Some info", "Info", { duration: 2 * 1000 });
        }
        else {
          if(!data.o){
            localStorage.setItem("fuelMapData","");            
            this.loadMarkers();
          }
        }
      },      
      error: (e) => { 
        this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); 
        console.log(e);
      }
    });
  }

  loadMarkersInMap() {
    this.markers = [];
    this.tableData = new MatTableDataSource(this.mapData.o);
    if(this.mapData.o?.length == 0) {
      this.gMap.center = { lat: 23.3, lng:13.58 };
      this.gMap.zoom = 2;
      this.snackBar.open("No ports available for your filter criteria!!", "No Records", { duration: 5 * 1000 });
      return;
    }
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.tableData.filter = this.searchKeywordFilter.value    
    this.mapData.o?.forEach(p=>{
      this.markers.push({
        position: {
          lat: p.lat,
          lng: p.lng,         
        },
        icon: this.chevronImage, 
        title: p.name,
        options: { animation: google.maps.Animation.DROP, draggable: false },
        context: p
      });
    });
    this.mapSetBounds();
  }
  
  applyFilter() {
    const filterValue = this.searchKeywordFilter.value;
    this.tableData.filter = filterValue.trim().toLowerCase();

    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  mapZoomChanged() {
    sessionStorage.setItem('FuelMapZoom', JSON.stringify(this.gMap.getZoom()));
  }

  mapCenterChanged() {
    sessionStorage.setItem('FuelMapCenter', JSON.stringify(this.gMap.getCenter()));
  }

  mapIdle(){
    const mapCenter = sessionStorage.getItem('FuelMapCenter');
    const zoomLevel = sessionStorage.getItem('FuelMapZoom');
    if (mapCenter && zoomLevel) {
      this.gMap.center = JSON.parse(mapCenter);
      this.gMap.zoom = JSON.parse(zoomLevel);
    }
  }

  mapShowMarkerInfo(port:any) {

    if(!this.markerNotes)
    {
      this.markerNotes = document.createElement('div');
      this.markerNotes.className = 'mapInfo';
      this.gMap.controls[google.maps.ControlPosition.TOP_LEFT].push(this.markerNotes);
    }
    if(port != null) {
      this.markerNotes.style = 'position:absolute';
      this.markerNotes.innerHTML = port.context.name + '<br/><i>Click port for further details</i>';
    }
    else {
      this.markerNotes.style = 'display:none';
    }   
  } 
 
  mapSetBounds() {
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach(m=>{
      bounds.extend(m.position);
    });
    this.gMap.center = bounds.getCenter();
    this.gMap.fitBounds(bounds);
  }


  openPopup(marker:MapMarker, port:any) {
    this.router.navigate(["fuel-port",port.context.id ]);    
  }

}

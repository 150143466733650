<h1>Port Handling <span style="font-size: 18px;">{{title}}</span></h1>
<div class="mat-elevation-z8">
  <div *ngxPermissionsOnly="[ WPDRole.admin, WPDRole.portAdmin, WPDRole.productAdmin ]">
    <button mat-fab matTooltip="CSV Download"  (click)="downloadExcelFile(false)" class="csv-download"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-csv" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z"/>
    </svg></button>
    <button mat-fab matTooltip="Excel Download"  (click)="downloadExcelFile()" class="excel-download"><mat-icon>download</mat-icon></button>
    <span *ngxPermissionsOnly="[ WPDRole.admin, WPDRole.portAdmin ]">
      <button mat-fab matTooltip="Excel Upload" (click)="fileInput.click()" class="excel-upload"><mat-icon>upload</mat-icon></button>
      <input #fileInput hidden="hidden" type="file" onclick="this.value=null" (change)="uploadFile($event)" accept=".xlsx"/>
    </span>
  </div>
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput placeholder="Ex. Houston or Taro Special or 219100" [formControl]="searchKeywordFilter" cvx-input-cache="PH-List-QSearch">
  </mat-form-field>

  <div class="wpd-loading-shade"
       *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div>
    <table mat-table [dataSource]="data" matSort matSortActive="id" matSortDirection="asc" aria-describedby="Port handling list">
      
      <ng-container matColumnDef="port">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Port</th>
        <td mat-cell *matCellDef="let row">{{row.port}}</td>
      </ng-container>

      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
        <td mat-cell *matCellDef="let row"><a [routerLink]="['/portHandling', row.id]">{{row.product}}</a></td>
      </ng-container>

      <ng-container matColumnDef="materialNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Material No.</th>
        <td mat-cell *matCellDef="let row">{{row.materialNo}}</td>
      </ng-container>

      <ng-container matColumnDef="mOQSet">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>MOQ</th>
        <td mat-cell class="text-center" *matCellDef="let row"> <mat-icon aria-hidden="false" fontIcon="{{row.moqSet ? 'check': 'close'}}" class="text-{{row.moqSet ? 'success': 'danger'}}"></mat-icon></td>
      </ng-container>

      <ng-container matColumnDef="bulk">
        <th mat-header-cell class="text-end" *matHeaderCellDef mat-sort-header>Bulk</th>
        <td mat-cell *matCellDef="let row">{{row.bulk}}</td>
      </ng-container>

      <ng-container matColumnDef="tote">
        <th mat-header-cell class="text-end" *matHeaderCellDef mat-sort-header>Tote</th>
        <td mat-cell *matCellDef="let row">{{row.tote}}</td>
      </ng-container>

      <ng-container matColumnDef="drum">
        <th mat-header-cell class="text-end" *matHeaderCellDef mat-sort-header>Drum</th>
        <td mat-cell *matCellDef="let row">{{row.drum}}</td>
      </ng-container>

      <ng-container matColumnDef="pail">
        <th mat-header-cell class="text-end" *matHeaderCellDef mat-sort-header>Pail</th>
        <td mat-cell *matCellDef="let row">{{row.pail}}</td>
      </ng-container>

      <ng-container matColumnDef="case">
        <th mat-header-cell class="text-end" *matHeaderCellDef mat-sort-header>Case</th>
        <td mat-cell *matCellDef="let row">{{row.case}}</td>
      </ng-container>

      <ng-container matColumnDef="barge">
        <th mat-header-cell class="text-end" *matHeaderCellDef mat-sort-header>Barge</th>
        <td mat-cell *matCellDef="let row">{{row.barge}}</td>
      </ng-container>   
     
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openRecord(['/portHandling', row.id])"></tr>
      

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data matching the filter "{{tableFilter.quickFilter}}"</td>
      </tr>
    </table>
  </div>

  <mat-paginator [length]="resultsLength" [pageSize]="100" [pageSizeOptions]="[100, 200, 300, 400, 500]" [showFirstLastButtons]="true"></mat-paginator>
</div>

export const environment = {
  production: false,
  serviceURL: 'https://wpd-api-dev.azure.chevron.com/',
  osURL: 'https://chevronproductsuklim-dev.outsystemsenterprise.com',
  osApiURL: 'https://outservice-dev.azure.chevron.com/',
  oidc: {
    clientId: '0oa1pu8vi23WIkDIY0h8',
    issuer: 'https://oktapreview.chevron.com/oauth2/default',
    redirectUri: 'https://wpd-dev.azure.chevron.com/assets/callback.html',    
    pkce: true,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    idp: '0oasvj22ahjNzHAYX0h7',
    testing: {
      disableHttpsCheck: false
    },
  },
  widget: {
    USE_CLASSIC_ENGINE: false,
  }
};

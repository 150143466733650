
<div class="form-horizontal">
    <div class="wpd-loading-shade"
        *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <h3>
      <a [routerLink]="['/portHandling', null, null, data.id]" class="phLink" matTooltip="Port Handling">
        <mat-icon>link</mat-icon>
      </a> 
      Delivery Agent: {{data._Name}} - 
      <a href="{{osURL + '/DAPortal/DAFeeSchedulesList.aspx?DANumber=' + (data._VendorNumber|slice:2:20)}}" 
      matTooltip="DA Portal" target="_blank" rel="noopener">
        {{data._VendorNumber|slice:2:20}} <mat-icon fontIcon="local_shipping"></mat-icon>
      </a>
    </h3>
    <form #loginForm="ngForm" (ngSubmit)="updateData()">    
      <mat-form-field class="col-md-6">
        <mat-label>Country</mat-label>
        <input matInput name="_Country" [(ngModel)]="data._Country" type="text" readonly />
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <mat-label>City</mat-label>
        <input matInput name="_City" [(ngModel)]="data._City" type="text" readonly />
      </mat-form-field>
      <mat-form-field class="col-md-2">
        <mat-label>Internal SLA</mat-label>
        <input matInput name="internalSLA" [(ngModel)]="data.internalSLA" type="number" />
      </mat-form-field>
      <mat-form-field class="col-md-2">
        <mat-label>Lead Times</mat-label>
        <input matInput name="leadTimes" [(ngModel)]="data.leadTimes" type="number" />
      </mat-form-field>
      <mat-form-field class="col-md-2">
        <mat-label>Tier</mat-label>
        <input matInput name="tier" [(ngModel)]="data.tier" type="number" />
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <mat-label>Min. Qty.</mat-label>
        <input matInput name="minQty" [(ngModel)]="data.minQty" type="number" />
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <mat-label>Max. Qty.</mat-label>
        <input matInput name="maxQty" [(ngModel)]="data.maxQty" type="number" />
      </mat-form-field>      

      <mat-label class="col-md-2">Min. Order Qty</mat-label>
      <div class="row">        
        <table class="table-MOQ" border="1" aria-describedby="Minimum order quantity">
          <thead>
            <tr><th scope="col" colspan="3" style="text-align:center">Customer</th></tr>
            <tr><th scope="col" ></th><th scope="col" >Barge/Launch</th><th scope="col">Truck</th></tr>
          </thead>
          <tbody>
            <tr>
              <td>Bulk</td>
              <td><textarea matInput name="moqBulkBarge" [(ngModel)]="data.moqBulkBarge" cdkTextareaAutosize></textarea></td>
              <td><textarea matInput name="moqBulkTruck" [(ngModel)]="data.moqBulkTruck" cdkTextareaAutosize></textarea></td>
            </tr>
            <tr>
              <td>Pack</td>
              <td><textarea matInput name="moqPackBarge" [(ngModel)]="data.moqPackBarge" cdkTextareaAutosize></textarea></td>
              <td><textarea matInput name="moqPackTruck" [(ngModel)]="data.moqPackTruck" cdkTextareaAutosize></textarea></td>
            </tr>
            <tr>
              <td>Combine</td>
              <td><textarea matInput name="moqAllBarge" [(ngModel)]="data.moqAllBarge" cdkTextareaAutosize></textarea></td>
              <td><textarea matInput name="moqAllTruck" [(ngModel)]="data.moqAllTruck" cdkTextareaAutosize></textarea></td>
            </tr>
          </tbody>
        </table>
      </div>
      <mat-form-field class="col-md-12">
        <mat-label>Foot notes</mat-label>
        <textarea matInput name="footNotes" [(ngModel)]="data.footNotes" cdkTextareaAutosize></textarea>
      </mat-form-field>
      <mat-form-field class="col-md-12">
        <mat-label>Additional Lead Time</mat-label>
        <textarea matInput name="additionalLeadTime" [(ngModel)]="data.additionalLeadTime" cdkTextareaAutosize></textarea>
      </mat-form-field>
      <mat-form-field class="col-md-12">
        <mat-label>Pumping from Packages Available</mat-label>
        <textarea matInput name="pumpingAvailability" [(ngModel)]="data.pumpingAvailability" cdkTextareaAutosize></textarea>
      </mat-form-field>
      <mat-form-field class="col-md-12">
        <mat-label>Additional Charges</mat-label>
        <textarea matInput name="additionalCharges" [(ngModel)]="data.additionalCharges" cdkTextareaAutosize></textarea>
      </mat-form-field>
      <mat-form-field class="col-md-12">
        <mat-label>Other Information</mat-label>
        <textarea matInput name="otherInformation" [(ngModel)]="data.otherInformation" cdkTextareaAutosize></textarea>
      </mat-form-field>

      <div class="padding-top-24" *ngIf="data.canUpdate">
        <div class="col-md-offset-2 col-md-12">
          <div [ngClass]="'alert ' + result.e == null ?'alert-danger' : 'alert-success'" [innerHTML]="result.e??result.i"></div>
          <button mat-raised-button color="primary" type="submit">Save</button>
        </div>
      </div>
    </form>
    <mat-accordion>
      <mat-expansion-panel  (opened)="false">
        <mat-expansion-panel-header>
        <mat-panel-title>
            Ports
        </mat-panel-title>
        <mat-panel-description>
            List of associated Ports
        </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-card *ngIf="data._InheritedPorts.length > 0">
          <mat-card-header>
            <mat-card-title>No Port Specific Details</mat-card-title>           
          </mat-card-header>
          <mat-card-content>
            <div class="row" style="font-size: 12px;">
              <div class="col-sm-3" *ngFor="let p of data._InheritedPorts">
                  <a [routerLink]="['/port', p.id]">{{p.name}} <mat-icon *ngIf="!p.visible" matTooltip="Not visible to customers">location_off</mat-icon></a>
              </div>              
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card style="margin-top: 10px;" *ngIf="data._OverriddenPorts.length > 0">
          <mat-card-header>
            <mat-card-title>Port Specific Details</mat-card-title>           
          </mat-card-header>
          <mat-card-content>
            <div class="row" style="font-size: 12px;">
              <div class="col-sm-3" *ngFor="let p of data._OverriddenPorts">
                  <a [routerLink]="['/port', p.id]">{{p.name}} <mat-icon *ngIf="!p.visible" matTooltip="Not visible to customers">location_off</mat-icon></a>
              </div>              
            </div>
          </mat-card-content>
        </mat-card>
      </mat-expansion-panel>        
    </mat-accordion>
</div>

import { Component } from '@angular/core';
import { BaseListComponent } from '../common/base-list.component';
import { CountryList } from '../models/countryVM';
import { CountryService } from '../services/country.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'wpd-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.css']
})

export class CountryListComponent extends BaseListComponent<CountryList> {
  displayedColumns: string[] = ['name', 'region', 'ports', 'isActive'];
  fIsActive = new FormControl();
  fPortExist = new FormControl();
  constructor(service: CountryService, router: Router, protected snackBar: MatSnackBar) {
    super(service, router, snackBar);
  }

  override getPageFilter(): any { 
    return { fIsActive: this.fIsActive.value, fPortExist: this.fPortExist.value };
  };
}

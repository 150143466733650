import { HttpErrorResponse } from '@angular/common/http';
import { throwError} from 'rxjs';
import { AppError } from '../common/app-error';


export class BaseService {

    //***********************
    //***** Utility *********
    //***********************
    isNumber(value?: string | number): boolean {
        return ((value != null) &&
            (value !== '') &&
            !isNaN(Number(value.toString())));
    }

    parseDate(dateString: string): Date|null {
        if (dateString) {
            return new Date(dateString);
        } else {
            return null;
        }
    }

    protected handleError(error: HttpErrorResponse) : any
    {
        if (error.status === 400)
            return throwError(() => new AppError(error));       
        if (error.status === 404)
            return throwError(() => new AppError(error)); 

        return throwError(() => new AppError(error)); 
    }
}

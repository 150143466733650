import { Component, Inject } from '@angular/core';
import { BaseUpsert } from '../common/base-upsert.component';
import { PortService } from '../services/port.service';
import { ActivatedRoute } from '@angular/router';
import { PortVM } from '../models/portVM';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TooltipComponent } from '@angular/material/tooltip';
export type DialogDataSubmitCallback<T> = (row: T) => void;

@Component({
  selector: 'wpd-port',
  templateUrl: './port.component.html',
  styleUrls: ['./port.component.css']
})
export class PortComponent extends BaseUpsert<PortVM> {
  constructor(service: PortService, route: ActivatedRoute, snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) contextData?: { callback: DialogDataSubmitCallback<PortVM>; id: BigInteger },
    dialogRef?: MatDialogRef<PortComponent>
    ) {
    super(service, route, snackBar, contextData, dialogRef);

    Object.defineProperty(TooltipComponent.prototype, 'message', {
      set(v: any) {        
        this._tooltip.nativeElement.lastChild.innerHTML = v.replace(/(?:\r\n|\r|\n)/g, '<br />');        
      },
      configurable: true,
    });

  }
  
}

import { Component } from '@angular/core';
import { BaseListComponent } from '../common/base-list.component';
import { DeliveryAgentList } from '../models/deliveryAgentVM';
import { DeliveryAgentService } from '../services/deliveryAgent.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-delivery-agent-list',
  templateUrl: './delivery-agent-list.component.html',
  styleUrls: ['./delivery-agent-list.component.css']
})
export class DeliveryAgentListComponent extends BaseListComponent<DeliveryAgentList> {
  displayedColumns: string[] = ['id', 'name', 'vendorNumber', 'city', 'country', 'ports', 'x'];
  osURL: string;
  fPortExist = new FormControl(true);
  constructor(service: DeliveryAgentService, router: Router, protected snackBar: MatSnackBar) {
    super(service, router, snackBar);
    this.osURL = environment.osURL;
  }

  override getPageFilter(): any { 
    return { fPortExist: this.fPortExist.value };
  };
}

import { AuthService } from './../services/auth.service';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogDataSubmitCallback } from '../common/base-upsert.component';
import { MapService } from '../services/map.service';
import { NotesVM, PortDetailVM } from '../models/portDetailVM';
import { APIResultVM } from '../models/apiResultVM';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { BaseComponent } from '../common/base.component';

interface LineNotes {
  note: string;
  subNotes?: LineNotes[]
}

@Component({
  selector: 'app-port-detail',
  templateUrl: './port-detail.component.html',
  styleUrls: ['./port-detail.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PortDetailComponent extends BaseComponent implements OnInit {

  constructor(protected service: MapService, protected route: ActivatedRoute, protected snackBar: MatSnackBar, authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public contextData?: { callback: DialogDataSubmitCallback<PortDetailVM>; id: BigInteger },
    protected dialogRef?: MatDialogRef<PortDetailComponent>
    ) {
    super();
    authService.loadPermissionsFromSession();
  }
  liMatch = "#!#";
  id: BigInteger;
  isLoadingResults = true;
  isDialog = false;
  data?: PortDetailVM;
  categories?: string[];

  additionalLeadTime: string;
  pumpingAvailability: string;
  additionalCharges: string;
  otherInformation: string;
  footNotes?: string[];

  searchKeywordFilter = new FormControl();
  tableData: MatTableDataSource<any>;
  @ViewChild(MatSort, {static: false})
   set sort(sort: MatSort) {
    if(this.tableData) {
      this.tableData.sort = sort;
    }
   }
  displayedColumns: string[] = ['cat', 'prd', 'b', 't', 'd', 'p', 'c', 'br'];
  
  ngOnInit() {

    this.route.paramMap.subscribe(paramMap => {
      this.id = <BigInteger><unknown>(paramMap.get('id'));
      if (!this.id) {
        this.id = <BigInteger>(this.contextData?.id??"0");
        this.isDialog = (this.contextData?.id != null);
      }
      // Call your API service and update the local data
      this.isLoadingResults = true;
      return this.service.getPortDetail(this.id).subscribe({
        next: (resp: APIResultVM<PortDetailVM>) => {
          if (resp.e != null) {
            this.snackBar.open(resp.e ?? "", "Error");
          }
          else if (resp.i != null) {
            this.snackBar.open(resp.i ?? "Some info", "Info", { duration: 2 * 1000 });
          }
          else {
            this.data = resp.o;
            if (this.data) {
              this.setMinimumQuantity();
              this.categories = this.data.portHandling?.filter((item, i, arr) => arr.findIndex((t) => t.cat === item.cat) === i).map(ph => { return ph.cat });
              this.additionalLeadTime = this.loadArrayItems(this.data.additionalLeadTime);
              this.additionalCharges = this.loadArrayItems(this.data.additionalCharges);
              this.pumpingAvailability = this.loadArrayItems(this.data.pumpingAvailability);
              this.otherInformation = this.loadArrayItems(this.data.otherInformation);
              this.data._notice = this.getNoticeData(this.data?.notice, '');              
              this.data.portHandling?.forEach((item, i) => {
                item._b = this.getNoticeData(item.b, '');
                item._br = this.getNoticeData(item.br, '');
                item._c = this.getNoticeData(item.c, '');
                item._d = this.getNoticeData(item.d, '');
                item._p = this.getNoticeData(item.p, '');
                item._t = this.getNoticeData(item.t, '');                
              });
              this.footNotes = this.data.footNotes?.split('\n');
              this.tableData = new MatTableDataSource(this.data.portHandling);
            }
          }          
        },
        error: (e) => { 
          console.log(e); 
          this.isLoadingResults = false;
          this.snackBar.open("Unknown error, please contact administrator!!!", "Error");
        },
        complete: () => { this.isLoadingResults = false; },
      });
    });
  }

  setMinimumQuantity() {
    if(this.data?.moqBulkBarge ||  this.data?.moqBulkTruck || this.data?.moqPackBarge ||  this.data?.moqPackTruck || this.data?.moqAllBarge || this.data?.moqAllTruck) {
      this.data.minimumQuantity = [
        { header: 'Bulk', bl: this.data.moqBulkBarge??'', truck: this.data.moqBulkTruck??''},
        { header: 'Pack', bl: this.data.moqPackBarge??'', truck: this.data.moqPackTruck??'' }
      ]
      if(!(this.data.moqAllBarge == this.data.moqAllTruck && this.data.moqAllBarge == null)) {
        this.data.minimumQuantity.push({ header: 'Combine', bl: this.data.moqAllBarge??'', truck: this.data.moqAllTruck??''});
      }
      
    }
  }


  getHtmlForLineItem(notes: LineNotes[]): string {
    let html: string[] =[];
    html.push("<ul>");
    notes.forEach(note => {
      html.push("<li>" + this.HtmlEncode(note.note) + "</li>");
      if(note.subNotes) {        
        html.push("<li class='list-style-none'>" + this.getHtmlForLineItem(note.subNotes) + "</li>");
      }
    });
    html.push("</ul>");
    return html.join("");
  }
  
  loadArrayItems(t: string|null|undefined): string {
    if(!t) return "";
    let notes  = t?.split('\n') ?? [];    
    let lineNotes: LineNotes[] = [];    
    for(let i=0; i< notes.length; i++)
    {
      let note = notes[i];
      let depthCount = 0
      while(i>0 && note.startsWith(this.liMatch)) {
        depthCount+=1;
        note = note.substring(this.liMatch.length, note.length);
      }
      let parentNotes: LineNotes[] = lineNotes;
      for(let j =0; j< depthCount; j++) {
        let parentNote = parentNotes[parentNotes.length - 1];
        if(!parentNote.subNotes) {parentNote.subNotes = []};
        parentNotes = parentNotes[parentNotes.length - 1].subNotes??[];
      }
      parentNotes.push({note: note});
    }
    return this.getHtmlForLineItem(lineNotes);
  }

  getNoticeData(notice: string | null | undefined, noText: string): NotesVM {
    const ret: NotesVM = { text: '', class:'', tooltip: '' };
    if (notice === '0') return ret;
    if (!notice) {
      ret.tooltip =  this.data?._notice?.tooltip??"";
      ret.class =  'badge-noText';
      return ret;
    }

    if (this.service.isNumber(notice??"") || notice =="X") 
    {           
      ret.class = 'badge';
      ret.tooltip = notice + ' working days';
      ret.text = notice;
      return ret;
    }

    ret.class = 'badge-info';
    ret.tooltip = notice;
    ret.text = "";
    return ret;
  }

  applyFilter()
  {
    const filterValue = this.searchKeywordFilter.value;
    this.tableData.filter = filterValue.trim().toLowerCase();

    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

}

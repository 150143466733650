import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TableFilter, ColumnSort } from '../models/table-filter';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as FileSaver from 'file-saver';
import { formatDate } from '@angular/common';
import { APIResultVM } from '../models/apiResultVM';
import { BaseComponent } from './base.component';

@Component({
  selector: 'cvx-base-list',
  template: `
    <p>
      base list view!
    </p>
  `
})

export class BaseListComponent<T> extends BaseComponent implements AfterViewInit {
  protected displayedColumns: string[] = ['id'];
  data: T[];
  colSort: ColumnSort = <ColumnSort>{};
  tableFilter: TableFilter = <TableFilter>{};
  searchKeywordFilter = new FormControl();
  

  resultsLength = 0;
  isLoadingResults = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private service: DataService, private router: Router, protected snackBar: MatSnackBar) {
    super()
  }

  ngAfterViewInit() {
    merge(this.sort.sortChange, this.searchKeywordFilter.valueChanges).subscribe(() => (this.paginator.pageIndex = 0));    
    merge(this.sort.sortChange, this.paginator.page, this.searchKeywordFilter.valueChanges)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this.tableFilter.currentPageNumber = this.paginator.pageIndex;
          this.tableFilter.pageSize = this.paginator.pageSize;
          this.tableFilter.quickFilter = this.searchKeywordFilter.value ?? "";
          this.tableFilter.pageFilter = this.getPageFilter();
          this.colSort.columnName = this.sort.active == "" ? "id" : this.sort.active;
          this.colSort.isAscending = this.sort.direction == "asc";
          this.tableFilter.sort = [];
          this.tableFilter.sort.push(this.colSort);

          return this.service.getList(this.tableFilter)
            .pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;

          if (data === null) { return []; }

          this.resultsLength = data.total_count;
          return data.items;
        }),
      )
      .subscribe(data => {
        this.data = data; 
        this.dataLoaded();
      });
  }
  
  filterChange(){
    //Trigger Search
    this.searchKeywordFilter.updateValueAndValidity();
  }

  getPageFilter(): any { return "" }

  dataLoaded() { return }

  openRecord(commands: any[]) : Promise<boolean>{
    return this.router.navigate(commands);
  }

  downloadExcelFile(isExcel = true){
    this.isLoadingResults = true;
    this.tableFilter.quickFilter = this.searchKeywordFilter.value ?? "";
    this.tableFilter.pageFilter = this.getPageFilter();
    this.service.downloadExcel(this.tableFilter, isExcel).subscribe({
      next: (data: any) => {
        this.downloadFile(data, isExcel);
      },      
      error: (e) => { this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); this.isLoadingResults = false; },
      complete: () => { this.isLoadingResults = false; }, 
    });
  }

  downloadFile(data: any, isExcel: boolean) {    
    const blob = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',  });
    const date = new Date();
    
    FileSaver.saveAs(blob,  this.service.controller + formatDate(date, '_yyyy-MM-dd_HHmm', 'en-US') + (isExcel?".xlsx":".csv"));
  }

  uploadFile(fileInputEvent: any) {
    if(fileInputEvent.target.files.length > 1) {
      this.snackBar.open("File Upload","Cannot process more than 1 file", { duration: 2 * 1000 })
      return;
    }
    this.isLoadingResults = true;
    this.service.uploadExcel(fileInputEvent.target.files[0]).subscribe({
      next: (response: any) => {      
        const data = <APIResultVM<string[]>> response;
        if (data.e) {
          this.snackBar.open(data.e ?? "", "Error");
        }
        else {          
          this.snackBar.open("Uploaded Successfully!!!", "Success", { duration: 2 * 1000 });
          this.ngAfterViewInit();
        }
        

      },      
      error: (e) => { this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); this.isLoadingResults = false; },
      complete: () => { this.isLoadingResults = false; }, 
    });
    console.log();
  }

}



import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class OutSystemService extends BaseService {    

    constructor(protected http: HttpClient) {
        super();
    } 
   
    setUserLoggedInDate(): Observable<any> {
        return this.http.post(environment.osURL + '/HybrisWS/rest/OS_CommonService/SetUserLoggedInDate?key=e03d8cd8-e87c-4ca3-91b5-dd22b189ae54', null).pipe(
            catchError(this.handleError)
        );
    }    
}

import { MapDeliveryAgentVM, MapDepotVM } from './../models/mapDeliveryAgentVM';
import { Observable, map, filter, take } from 'rxjs';
import { OktaAuth } from '@okta/okta-auth-js';
import { Component, ElementRef, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { ClusterIconStyle, GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MapService } from '../services/map.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { APIResultVM, OSApiResultVM } from '../models/apiResultVM';
import { MatDialog } from '@angular/material/dialog';
import { PortVM } from '../models/portVM';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../services/auth.service';
import { MapPopup } from './mapPopUp';
import { Router } from '@angular/router';
import { BaseComponent } from '../common/base.component';
import { MapSeaSearcherPlaceVM, MapSeaSearcherVM } from '../models/seaSearcherVM';
import { PortListComponent } from '../port-list/port-list.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'wpd-wpdmap',
  templateUrl: './wpdmap.component.html',
  styleUrls: ['./wpdmap.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class WpdMapComponent extends BaseComponent {
  zoom = 2;
  center: google.maps.LatLngLiteral = { lat: 23.3, lng:13.58 };
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    maxZoom: 10,
    minZoom: 2,
    streetViewControl: false,
    mapTypeControl: false,
    scaleControl: true,
    zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM }
  };

  defaultMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    animation: google.maps.Animation.DROP,

  }

  vesselInfo:any = { vesselId: "", notes: ""};
  
  //  polylineOptions = {
  //   path: [],
  //   geodesic: true,
  //   strokeColor: "#FF0000",
  //   strokeWeight: 0.5,
  //   strokeOpacity: 0,
  //   icons: [
  //       {
  //         icon: {
  //           path: "M 0,-1 0,1",
  //           strokeOpacity: 0.5,
  //           scale: 4,
  //         },
  //         offset: "0",
  //         repeat: "20px",
  //       },
  //     ]
  //   };



  markers : any[] = [];
  tableData: MatTableDataSource<any>;
  mapData : APIResultVM<any[]>;
  isLoadingResults = false;
  currPort: any = {};  
  clusterIconStyle: ClusterIconStyle =  {url: this.baseUrl + 'assets/images/m1.png',  width: 28,  height: 28,  textColor: '#000000', className:'wpd-cluster', anchorIcon: [0,0]};  
  clusterIconHMStyle: ClusterIconStyle = {  url: this.baseUrl + 'assets/images/m2.png',     width: 28,    height: 28, textColor: '#000000', className:'wpd-cluster', anchorIcon: [0,0]};

  chevronImage = this.baseUrl + "assets/images/marker-blue-1.png";
  chevronImageHm = this.baseUrl + "assets/images/marker-yellow-1.png";
  daImage = {
    url: this.baseUrl + "assets/images/icon_office.png",
    scaledSize: new google.maps.Size(30, 40),
  };
  depotImage = {
    url: this.baseUrl + "assets/images/icon_depot.png",
    scaledSize: new google.maps.Size(30, 40)
  };
  depotImageMissing = {
    url: this.baseUrl + "assets/images/icon_depot_missing.png",
    scaledSize: new google.maps.Size(30, 40)
  };
  vesselImage: google.maps.Symbol = {
    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    scale: 3,
    strokeColor: '#ff3174',
    fillColor: '#ff3174',
    rotation: 0,
    fillOpacity: 1
  };

  

  markerNotes: any;
  displayedColumns: string[] = ['name', 'notice', 'prdCnt', 'isHallmark', 'b', 'tt'];
  searchKeywordFilter = new FormControl();
  products: any[] = [];
  ports: any[] = [];
  selectedProducts: any[] = [];
  selectedPorts: any[] = [];
  selectedPackageType = "";
  mapSeaSearcher: MapSeaSearcherVM | undefined;
  selectedAltPorts : MapSeaSearcherPlaceVM[] | undefined= [];
  mapDeliveryAgentVMs: MapDeliveryAgentVM[] | undefined= [];
  mapDepotVMs: MapDepotVM[] | undefined= [];
  currentMapMarker: MapMarker; 

  @ViewChild('gMap', { static: false }) gMap: GoogleMap
  @ViewChild('markerVesselElem', { static: false }) gVesselMarker: MapMarker
  @ViewChild('infoWindow', { static: false }) gMapInfoWindow: MapInfoWindow
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('contextMenu') contextMenu: ElementRef<HTMLElement>;

  cacheKey_wpdCacheKey = "wpdCacheKey";
  cacheKey_mapData = "mapData";
  cacheKey_mapProducts = "mapProducts";
  cacheKey_mapPorts = "mapPorts";
  cacheKey_mapSelectedProducts = "mapSelectedProducts";
  cacheKey_mapSelectedPorts = "mapSelectedPorts";
  cacheKey_mapSelectedPackageType = "mapSelectedPackageType";
  cacheKey_LubeMapCenter = "LubeMapCenter";
  cacheKey_LubeMapZoom = "LubeMapZoom";
  cacheKey_seaSearcher = "seaSearcher";
  cacheKey_mapDA = "wpdMapDA";
  

  

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth, protected service: MapService, 
  protected snackBar: MatSnackBar, public dialog: MatDialog, public authService: AuthService,
  public router: Router, @Inject('BASE_URL') protected baseUrl: string
  ) {
    super();
    authService.loadPermissionsFromSession();
  }
 

  ngAfterViewInit() {
    const options = document.getElementById('mapOptionsWrapper')!;
    this.gMap.controls[google.maps.ControlPosition.TOP_LEFT].push(options);
    this.loadPageData();
  }
  
  loadPageData() {
    this.addPorts();
    this.addProducts();

    if(this.products.length > 0 
      && this.ports.length > 0 
      && this.selectedProducts.length == 0 
      && this.selectedPorts.length == 0 
      && this.selectedPackageType == "") {

        this.selectedProducts = JSON.parse(sessionStorage.getItem(this.cacheKey_mapSelectedProducts)??"[]");
        this.selectedPorts =  JSON.parse(sessionStorage.getItem(this.cacheKey_mapSelectedPorts)??"[]");
        this.selectedPackageType = sessionStorage.getItem(this.cacheKey_mapSelectedPackageType)??"";
    }

    this.loadMarkers();
    
  }

  addPorts() {
    if((localStorage.getItem(this.cacheKey_mapPorts)??"")!="") {
      this.ports = JSON.parse(localStorage.getItem(this.cacheKey_mapPorts)??"");      
      return;
    }
    this.getAutoList("Port");
  }

  addProducts() {
    if((localStorage.getItem(this.cacheKey_mapProducts)??"")!="") {
      this.products = JSON.parse(localStorage.getItem(this.cacheKey_mapProducts)??"");      
      return;
    }
    this.getAutoList("Product");
  }

  getAutoList(typ:"Product"|"Port") {
    this.service.getAutoList(typ,"").subscribe({          
      next: (data: APIResultVM<any[]>) => {
        if (data.e != null) {
          this.snackBar.open(data.e ?? "", "Error");
        }
        else if (data.i != null) {
          this.snackBar.open(data.i ?? "Some info", "Info", { duration: 2 * 1000 });
        }
        else {
          switch(typ)
          {
            case "Product":
              this.products = <any[]>data.o;
              localStorage.setItem(this.cacheKey_mapProducts, JSON.stringify(data.o??""));
              break;
            case "Port": 
              this.ports = <any[]>data.o;
              localStorage.setItem(this.cacheKey_mapPorts, JSON.stringify(data.o??"")); 
              break;          
          }           
        }
      },      
      error: (e) => { 
        this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); 
        console.log(e);
      }
    });

  }
 
  loadMarkers() {
    sessionStorage.setItem(this.cacheKey_mapSelectedProducts, JSON.stringify(this.selectedProducts));
    sessionStorage.setItem(this.cacheKey_mapSelectedPorts, JSON.stringify(this.selectedPorts));
    sessionStorage.setItem(this.cacheKey_mapSelectedPackageType, this.selectedPackageType);

    if(this.selectedPorts.length===0 && this.selectedProducts.length===0 && this.selectedPackageType == "" && (localStorage.getItem(this.cacheKey_mapData)??"")!="") {
      this.mapData = JSON.parse(localStorage.getItem(this.cacheKey_mapData)??"");
      this.loadMarkersInMap();
      this.validateCacheKey();
      return;
    }
    this.isLoadingResults = true;   
    this.service.getPorts(this.selectedPorts.join(","),this.selectedProducts.join(","),this.selectedPackageType,'','').subscribe(
    { 
      next: (data: APIResultVM<any[]>) => {        
        if (data.e != null) {
          this.snackBar.open(this.mapData.e ?? "", "Error");
        }
        else if (data.i != null) {
          this.snackBar.open(this.mapData.i ?? "Some info", "Info", { duration: 2 * 1000 });
        }
        else {
          this.mapData = data;
          if(this.selectedPorts.length===0 && this.selectedProducts.length===0 && this.selectedPackageType == ""){
            localStorage.setItem(this.cacheKey_mapData,JSON.stringify(data));
          }
          this.loadMarkersInMap();
        }
      },      
      error: (e) => { 
        this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); 
        console.log(e);
        this.isLoadingResults = false;
      },
      complete: () => { this.isLoadingResults = false; }, 

    });
  }

  validateCacheKey()
  {
    const key = localStorage.getItem(this.cacheKey_wpdCacheKey);

    this.service.validateCacheKey(key??"").subscribe({          
      next: (data: APIResultVM<any[]>) => {
        localStorage.setItem(this.cacheKey_wpdCacheKey, data.k??"");
        if (data.e != null) {
          this.snackBar.open(data.e ?? "", "Error");
        }
        else if (data.i != null) {
          this.snackBar.open(data.i ?? "Some info", "Info", { duration: 2 * 1000 });
        }
        else {
          if(!data.o){
            this.clearCacheKeys();
            this.loadPageData();
          }
        }
      },      
      error: (e) => { 
        this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); 
        console.log(e);
      }
    });
  }

  clearCacheKeys() {
    localStorage.setItem(this.cacheKey_mapData,"");
    localStorage.setItem(this.cacheKey_mapProducts,"");
    localStorage.setItem(this.cacheKey_mapPorts,"");
    localStorage.setItem(this.cacheKey_seaSearcher,"");
    localStorage.setItem(this.cacheKey_mapDA,"");
  }

  loadMarkersInMap(showDAonly: boolean = false) {
    this.markers = [];
    this.tableData = new MatTableDataSource(this.mapData.o);
    if(this.mapData.o?.length == 0) {
      this.gMap.center = { lat: 23.3, lng:13.58 };
      this.gMap.zoom = 2;
      if(!showDAonly) this.snackBar.open("No ports available for your filter criteria!!", "No Records", { duration: 5 * 1000 });
      return;
    }
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.tableData.filter = this.searchKeywordFilter.value
    //this.markers[0].getDraggable
    this.mapData.o?.forEach(p=>{
      this.markers.push({
        position: {
          lat: p.lat,
          lng: p.lng,         
        },
        icon: p.isHallmark ? this.chevronImageHm : this.chevronImage, 
        title: p.name,
        options: { animation: google.maps.Animation.DROP, draggable: false },
        context: p
      });
    });
    this.mapSetBounds("PORT");
  }
  
  applyFilter() {
    const filterValue = this.searchKeywordFilter.value;
    this.tableData.filter = filterValue.trim().toLowerCase();

    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  calculator(markers: any, cnt: number) {
    const filterDt = (<any[]>markers).filter(m=>m.icon.endsWith('marker-yellow-1.png')).map(m=>m.title);
    return { text: markers.length, index: (filterDt.length > 0)?2:1 , title:''};
  }

  mapZoomChanged() {
    sessionStorage.setItem(this. cacheKey_LubeMapZoom, JSON.stringify(this.gMap.getZoom()));
  }

  mapCenterChanged() {
    sessionStorage.setItem(this. cacheKey_LubeMapCenter, JSON.stringify(this.gMap.getCenter()));
  }

  mapIdle(){
    const mapCenter = sessionStorage.getItem(this. cacheKey_LubeMapCenter);
    const zoomLevel = sessionStorage.getItem(this. cacheKey_LubeMapZoom);
    if (mapCenter && zoomLevel) {
      this.gMap.center = JSON.parse(mapCenter);
      this.gMap.zoom = JSON.parse(zoomLevel);
    }
  }

  mapShowMarkerInfo(port:any) {

    if(!this.markerNotes)
    {
      this.markerNotes = document.createElement('div');
      this.markerNotes.className = 'mapInfo';
      this.gMap.controls[google.maps.ControlPosition.TOP_LEFT].push(this.markerNotes);
    }
    if(port != null) {
      this.markerNotes.style = 'position:absolute';
      this.markerNotes.innerHTML = port.context.name + '<br/><i>Click port for further details</i>';
    }
    else {
      this.markerNotes.style = 'display:none';
    }   
  }

  mapMarkerRightClick(e: google.maps.MapMouseEvent, port: any, index: number) {
    if (!this.loggedUser?.roles?.some(r => { return r == this.WPDRole.admin || r == this.WPDRole.portAdmin; })) return;
    this.contextMenu.nativeElement.innerHTML = port.context.name 
    + '<br/><a class="routerLink" href="/port/'+ port.context.id +'">Edit Port</a><br/>' +
    '<a class="moveMarker" href="'+index+'">Move Position</a><br/>' +
    '<a class="showAltPorts" href="'+port.context.id+'">Show Alternate Ports</a><br/>' +    
    '<a class="clearCache" href="javascript:void(0)">Reset External Cache</a>';    
    
    this.contextMenu.nativeElement.style.display = 'block';
    if(e.latLng) 
    {
      const popUp = new MapPopup(e.latLng, this.contextMenu.nativeElement);      
      if(this.gMap.googleMap) popUp.setMap(this.gMap.googleMap);      
    }    
  }

  mapDeliveryAgentRightClick(e: google.maps.MapMouseEvent, item: any, mapMarker: MapMarker, type: string) {
    if (!this.loggedUser?.roles?.some(r => { return r == this.WPDRole.admin || r == this.WPDRole.portAdmin; })) return;
    this.currentMapMarker = mapMarker;

    if(type=="DA") {
      this.contextMenu.nativeElement.innerHTML = item.name + ' (' + item.vendorNumber + ')'
      + '<br/><a class="routerLink" href="/delivery-agent/'+ item.id +'">Edit DA</a><br/>' +
      '<a class="moveDAMarker" href="' + item.id + '">Move Position</a><br/>' +
      '<a class="showDADepot" href="' + item.vendorNumber + '">Show Depots</a><br/>' +
      '<a class="showDAPorts" href="' + item.id + '">Show DA Ports</a><br/>' +
      '<a class="clearCache" href="javascript:void(0)">Reset External Cache</a>';
    }
    else if(type=="DEPOT") {
      this.contextMenu.nativeElement.innerHTML = item.name + ' (Depot)'
      + '<br/><a class="routerLink" href="/depot/'+ item.id +'">Edit Depot</a><br/>' +
      '<a class="moveDAMarker" href="' + item.id + '">Move Position</a><br/>' +
      '<a class="showDepotPorts" href="' + item.deliveryAgentId + '|' + item.id + '">Show Ports</a><br/>' +
      '<a class="clearCache" href="javascript:void(0)">Reset External Cache</a>';
    }

    
    this.contextMenu.nativeElement.style.display = 'block';
    if(e.latLng) 
    {
      const popUp = new MapPopup(e.latLng, this.contextMenu.nativeElement);      
      if(this.gMap.googleMap) popUp.setMap(this.gMap.googleMap);      
    }    
  }

  mapClick()
  {
    if(this.contextMenu.nativeElement)
      this.contextMenu.nativeElement.style.display = 'none';
  }

  mapSetBounds(itemType: string) {
    const bounds = new google.maps.LatLngBounds();
    switch(itemType) {
      case "DA": 
      break;
      case "DEPOT": 
      break;
      case "VESSEL": 
        bounds.extend(this.vesselInfo);        
        let center = bounds.getCenter();
        bounds.extend({lat:center.lat()+1, lng:center.lng()+1});   
        bounds.extend({lat:center.lat()-1, lng:center.lng()-1});        
      break;      
      case "PORT":
      default:
        this.markers.forEach(m=>{
          bounds.extend(m.position);
        });
      break;
    }
    
    this.gMap.center = bounds.getCenter();
  
    setTimeout(() => {
      // time-out to allow some time to render the clusterer properly
      this.gMap.fitBounds(bounds);
    }, 0.1);
    
  }

  openPopup(marker:MapMarker, port:any) {
    this.router.navigate(["portDetail",port.context.id ]);    
  }
  popupCallBack(vm:PortVM)
  {
    console.log(vm);

  }
  onMoveMarkerRequest(index: number) {      
      const marker = this.markers[index];
      marker.options.draggable = true;
      this.markers[index] = JSON.parse(JSON.stringify(marker));
  }  

  mapMarkerDragEnd(e: google.maps.MapMouseEvent, item:any, itemType: string) {    
    let calFunc: Observable<any>;
    let lat = e.latLng?.lat()!
    let lng = e.latLng?.lng()!
    switch(itemType) {
      case "DA": 
        calFunc = this.service.updateDALocation(item.id, lat, lng).pipe(take(1));

        //persist in local storage
        let da = this.mapDeliveryAgentVMs?.find(da=>da.vendorNumber === item.vendorNumber)!;
        if(!da) break;
        da.position = {lat: lat, lng: lng};
        localStorage.setItem(this.cacheKey_mapDA, JSON.stringify(this.mapDeliveryAgentVMs));

        break;
      case "DEPOT": 
        calFunc = this.service.updateDepotLocation(item.id, lat, lng);
        //persist in local storage
        let da2 = this.mapDeliveryAgentVMs?.find(da=>parseInt(da.vendorNumber) === parseInt(item.deliveryAgentId));
        let depot = da2?.depots?.find(d=>d.id === item.id)!;        
        if(!depot) break;
        depot.position = {lat: lat, lng: lng};
        depot.lat = lat;
        depot.lng = lng.toString();
        localStorage.setItem(this.cacheKey_mapDA, JSON.stringify(this.mapDeliveryAgentVMs));
        break;
      default: 
        calFunc = this.service.updatePortLocation(item.context.id, lat, lng);
    }
    calFunc.subscribe({          
      next: (data: APIResultVM<any>|OSApiResultVM<any[]>) => {
        if('e' in data) {
          if (data.e != null) {
            this.snackBar.open(data.e ?? "", "Error");
          }
          else if (data.i != null) {
            this.snackBar.open(data.i, "Info", { duration: 2 * 1000 });
          }
          else {
            this.snackBar.open("Updated Successfully", "Success", { duration: 2 * 1000 });
          }
        }
        else if('success' in data) {

          if (data.errors?.length != null) {
            this.snackBar.open(data.errors.join("\n") ?? "", "Error");
          }
          else if (data.message != null) {
            this.snackBar.open(data.message, "Info", { duration: 2 * 1000 });
          }
          else {
            this.snackBar.open("Updated Successfully", "Success", { duration: 2 * 1000 });
          }
        }
        
      },      
      error: (e) => { 
        this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); 
        console.log(e);
      }
    });
  }

  onClearCacheRequest() {      
    this.service.clearCacheKey().subscribe({          
      next: (data: APIResultVM<any[]>) => {
        if (data.e != null) {
          this.snackBar.open(data.e ?? "", "Error");
        }
        else if (data.i != null) {
          this.snackBar.open(data.i ?? "Some info", "Info", { duration: 2 * 1000 });
        }
        else {
          this.snackBar.open("Cache Cleared", "Success", { duration: 2 * 1000 });
        }
      },      
      error: (e) => { 
        this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); 
        console.log(e);
      }
    });
  }

  loadAlternatePorts(portId: number) {
    if((localStorage.getItem(this.cacheKey_seaSearcher)??"")!="") {
      this.mapSeaSearcher = JSON.parse(localStorage.getItem(this.cacheKey_seaSearcher)??"");      
      this.showAlternatePorts(portId);
      return;
    }
    this.service.getSeaSearcher().subscribe(
      { 
        next: (data: APIResultVM<MapSeaSearcherVM>) => {        
          if (data.e != null) {
            this.snackBar.open(this.mapData.e ?? "", "Error");
          }
          else if (data.i != null) {
            this.snackBar.open(this.mapData.i ?? "Some info", "Info", { duration: 2 * 1000 });
          }
          else {
            this.mapSeaSearcher = data.o;
            if(this.selectedPorts.length===0 && this.selectedProducts.length===0 && this.selectedPackageType == ""){
              localStorage.setItem(this. cacheKey_seaSearcher,JSON.stringify(data.o));
            }
            this.showAlternatePorts(portId);
          }
        },      
        error: (e) => { 
          this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); 
          console.log(e);
          this.isLoadingResults = false;
        },
        complete: () => { this.isLoadingResults = false; }, 
  
      });    
  }  

  showAlternatePorts(portId: number) {
    let mappings = this.mapSeaSearcher?.mappings?.filter(m=>m.portId == portId);
    this.selectedAltPorts = this.mapSeaSearcher?.places?.filter(p=> mappings?.find(m => m.placeId == p.id));
    this.selectedAltPorts?.map(p=>{ p.position = { lat: p.lat, lng: p.lng } });
  }

  loadDAs() {
    if((localStorage.getItem(this.cacheKey_mapDA)??"")!="") {
      this.mapDeliveryAgentVMs = JSON.parse(localStorage.getItem(this.cacheKey_mapDA)??"");
      return;
    }

    this.service.getDAs().subscribe(
      { 
        next: (data: APIResultVM<MapDeliveryAgentVM[]>) => {        
          if (data.e != null) {
            this.snackBar.open(this.mapData.e ?? "", "Error");
          }
          else if (data.i != null) {
            this.snackBar.open(this.mapData.i ?? "Some info", "Info", { duration: 2 * 1000 });
          }
          else {
            this.mapDeliveryAgentVMs = data.o;
            this.mapDeliveryAgentVMs?.map(p=>{ p.position = { lat: p.lat, lng: p.lng } });
            localStorage.setItem(this.cacheKey_mapDA, JSON.stringify(data.o));
          }
        },      
        error: (e) => { 
          this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); 
          console.log(e);
          this.isLoadingResults = false;
        },
        complete: () => { this.isLoadingResults = false; }  
      });    
  }

  hideDAs() {
    this.mapDeliveryAgentVMs = undefined;
    this.mapDepotVMs = [];
  }

  loadDepots(vendorNumber: string) {
    let da = this.mapDeliveryAgentVMs?.find(da=>da.vendorNumber === vendorNumber)!;
    if(da===undefined) return;
    if(da._depotsLoaded) {
      this.mapDepotVMs = [];
      this.mapDepotVMs = da.depots;
      console.log(this.mapDepotVMs);
      return;
    }
    this.service.getDepots(vendorNumber).subscribe(
      { 
        next: (data: OSApiResultVM<MapDepotVM[]>) => {        
          if (!data.success) {
            this.snackBar.open(data.errors?.join("\n")?? "", "Error");
          }
          else if(data.message != null) {
            this.snackBar.open(data.message, "Info", { duration: 2 * 1000 });
          }
          else {
            da.depots = data.data;
            da.depots?.map(p=>{ p.position = { lat: p.lat!=0?p.lat:da.lat, lng: +p.lng!=0?+p.lng:da.lng + 0.3 } });
            da._depotsLoaded = true;
            localStorage.setItem(this.cacheKey_mapDA, JSON.stringify(this.mapDeliveryAgentVMs));
            this.mapDepotVMs = da.depots;
            if(!this.mapDepotVMs?.length) this.snackBar.open("No depots available for the selected DA!!!", "Info", { duration: 2 * 1000 });
          }
        },      
        error: (e) => { 
          this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); 
          console.log(e);
          this.isLoadingResults = false;
        },
        complete: () => { this.isLoadingResults = false; }, 
  
      });    
  }

  showPortsByPortName(portNames:string[]) {
    portNames.map(i=>i.toUpperCase());
    let allData = JSON.parse(localStorage.getItem(this.cacheKey_mapData)!);
    let items= allData.o.filter((p:any)=>portNames.indexOf(p.name.toUpperCase())>=0);
    this.mapData.o = items;
    this.loadMarkersInMap();
  }

  loadDepotPorts(vendorNumber: string, depotId: number) {
    let da = this.mapDeliveryAgentVMs?.find(da=>parseInt(da.vendorNumber) === parseInt(vendorNumber))!;
    let depot = da.depots?.find(d=>d.id == depotId)!;
    if(depot===undefined) return;
    if(depot._portsLoaded) {
      this.showPortsByPortName(depot.ports!);
    }
    this.service.getDepotPorts(depotId).subscribe(
      { 
        next: (data: OSApiResultVM<string[]>) => {        
          if (!data.success) {
            this.snackBar.open(data.errors?.join("\n")?? "", "Error");
          }
          else if(data.message != null) {
            this.snackBar.open(data.message, "Info", { duration: 2 * 1000 });
          }
          else {
            depot.ports = data.data;
            depot._portsLoaded = true;
            localStorage.setItem(this.cacheKey_mapDA, JSON.stringify(this.mapDeliveryAgentVMs));
            this.showPortsByPortName(depot.ports!);
            
          }
        },      
        error: (e) => { 
          this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); 
          console.log(e);
          this.isLoadingResults = false;
        },
        complete: () => { this.isLoadingResults = false; }, 
  
      });    
  }

  loadDAPorts(daId: number) {
    let allData = JSON.parse(localStorage.getItem(this.cacheKey_mapData)!);
    let items= allData.o.filter((p:any)=>p.da == daId);
    this.mapData.o = items;
    this.loadMarkersInMap();
  }

  loadVessel(imo: number) {
    this.isLoadingResults = true;
    this.service.getVesselLocation(imo).pipe(take(1)).subscribe(
      { 
        next: (data:any) => {          
          if(data.success) {
            this.vesselInfo = data.data;            
            this.vesselImage = {
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  scale: 3,
                  strokeColor: '#ff3174',
                  fillColor: '#ff3174',
                  rotation: this.vesselInfo.heading,
                  fillOpacity: 1
                };
                if(this.vesselInfo.messageTimestamp) this.vesselInfo.messageTimestamp = formatDate(new Date(this.vesselInfo.messageTimestamp), "dd MMM yyyy HH:mm","en-US");
                if(this.vesselInfo.eta) this.vesselInfo.eta = formatDate(new Date(this.vesselInfo.eta), "dd MMM yyyy HH:mm","en-US");


                this.vesselInfo.notes =
                '<div class="vessel-info" style="width: 240px">' +                
                  '<h4>'+this.vesselInfo.vesselName + ' ('+  this.vesselInfo.imo + ')</h4>' +
                  '<div class="v-body">' +
                    "<div><span>Vessel Type: </span><span class='v-ellipse' title='"+this.vesselInfo.vesselType+"'>"+this.vesselInfo.vesselType+" </span></div>" +
                    "<div><span>Timestamp:</span> "+this.vesselInfo.messageTimestamp?.toString()+"</div>" +
                    "<div><span>Speed:</span> "+this.vesselInfo.speed+"</div>" +
                    "<div><span>Nearest Port:</span> "+this.vesselInfo.nearestPlace+(this.vesselInfo.nearestCountry?", " + this.vesselInfo.nearestCountry: "")+"</div>" +
                    "<div><span>Destination:</span> "+this.vesselInfo.destination+"</div>" +
                    "<div><span>ETA:</span> "+this.vesselInfo.eta?.toString()+"</div>" +
                  "</div>" +
                "</div>";
                this.openCloseVesselInfoWindow(true);
          }
          else {
            this.snackBar.open(data.errors.join(",")??"Vessel not found!!!", "Error"); 
            this.vesselInfo = null;
            this.openCloseVesselInfoWindow(false);
          }
          this.mapSetBounds("VESSEL");
        },      
        error: (e) => { 
          this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); 
          console.log(e);
          this.isLoadingResults = false;
        },
        complete: () => { this.isLoadingResults = false; }, 
  
      });
  }
  public openCloseVesselInfoWindow(open:boolean) {
    if(open) {
      this.gMapInfoWindow.open(this.gVesselMarker);
    }
    else {
      this.gMapInfoWindow.close();
    }
  }

  showHideMarkers(itemType: any) {
    if(itemType.value == "DA") {
      this.loadDAs();
      this.mapData.o = [];
      this.loadMarkersInMap(true);
    }
    else if(itemType.value == "VESSEL") {
      //this.dialog.open(PortListComponent, {});
    }
    else {
      this.hideDAs();
      this.loadMarkers();
    }    
  }
  showVessel(imo: any) {
    this.hideDAs();
    this.mapData.o = [];
    this.loadMarkersInMap(true);
    this.loadVessel(imo.value);

  }


  public handleClick(event: Event): void {
    if (event.target instanceof HTMLAnchorElement) {
      const element = event.target;      
      if (element.className === 'routerLink') {
        event.preventDefault();
        const route = element?.getAttribute('href');
        if (route) {
          this.router.navigate([`/${route}`]);
        }
      }
      else if(element.className === 'moveMarker') {
        event.preventDefault();
        this.onMoveMarkerRequest(<number>(element?.getAttribute('href')??0));
      }
      else if(element.className === 'showAltPorts') {
        event.preventDefault();
        this.loadAlternatePorts(<number>(element?.getAttribute('href')??0));
      }
      else if(element.className === 'showDAPorts') {
        event.preventDefault();
        let inputParam = element?.getAttribute('href')??"0";
        this.loadDAPorts(<number><unknown>(inputParam));
      }
      else if(element.className === 'moveDAMarker') {
        event.preventDefault();        
        this.currentMapMarker.marker?.setDraggable(true);
      }
      else if(element.className === 'showDADepot') {
        event.preventDefault();
        this.loadDepots(element?.getAttribute('href')??"");
      }
      else if(element.className === 'showDepotPorts') {
        event.preventDefault();
        let inputParam = element?.getAttribute('href')??"0|0";
        this.loadDepotPorts(inputParam.split("|")[0], <number><unknown>(inputParam.split("|")[1]));
      }      
      else if(element.className === 'clearCache') {
        event.preventDefault();
        this.onClearCacheRequest();
      }
    }
  }
}

import { Component, Inject } from '@angular/core';
import { BaseUpsert, DialogDataSubmitCallback } from '../common/base-upsert.component';
import { ProductVM } from '../models/productVM';
import { ProductService } from '../services/product.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WpdMapComponent } from '../wpdmap/wpdmap.component';

@Component({
  selector: 'wpd-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent extends BaseUpsert<ProductVM> {
  selectedProductsToOverride: any[] = [];

  constructor(service: ProductService, route: ActivatedRoute, snackBar: MatSnackBar, protected wpdMapComponent: WpdMapComponent, 
    @Inject(MAT_DIALOG_DATA) contextData?: { callback: DialogDataSubmitCallback<ProductVM>; id: BigInteger },
    dialogRef?: MatDialogRef<ProductComponent>
    ) {
    super(service, route, snackBar, contextData, dialogRef);
    wpdMapComponent.addProducts();
  }

  override dataLoaded(): void {
    this.selectedProductsToOverride = this.data.productsToOverride.split(",");
  }

  updateProductsToOverride() {
    this.data.productsToOverride = this.selectedProductsToOverride.join(",");
    console.log(this.selectedProductsToOverride);
  }

}
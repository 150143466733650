<wpd-home></wpd-home>
<div class="wpd-loading-shade" *ngIf="isLoadingResults">
  <mat-spinner mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<div>  
  <ng-select placeholder="Products (please select 1 or more)"
  [items]="products" bindLabel="text" bindValue="id" [(ngModel)]="selectedProducts"
  [multiple]="true" [hideSelected]="true" 
  (add)="loadMarkers()" (remove)="loadMarkers()" (clear)="loadMarkers()">
    <ng-template ng-option-tmp let-item="item.text" let-search="searchTerm">
      <span [ngOptionHighlight]="search">{{item}}</span>
    </ng-template>
  </ng-select>

  <ng-select placeholder="Ports (please select 1 or more)" 
  [items]="ports" bindLabel="text" bindValue="id" [(ngModel)]="selectedPorts"
  [multiple]="true" [hideSelected]="true" 
  (add)="loadMarkers()" (remove)="loadMarkers()" (clear)="loadMarkers()">
    <ng-template ng-option-tmp let-item="item.text" let-search="searchTerm">
      <span [ngOptionHighlight]="search">{{item}}</span>
    </ng-template>
  </ng-select>

  <div>
    <mat-label>Package</mat-label>
    <mat-radio-group [(ngModel)]="selectedPackageType" (change)="loadMarkers()">
      <mat-radio-button value="">Any</mat-radio-button>
      <mat-radio-button value="bulk">Bulk</mat-radio-button>
      <mat-radio-button value="tote">Tote/IBC</mat-radio-button>
      <mat-radio-button value="drum">Drum</mat-radio-button>
      <mat-radio-button value="pail">Pail</mat-radio-button>
      <mat-radio-button value="case">Case</mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div class="mat-elevation-z8">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon matTooltip="Map view">place</mat-icon>
        </ng-template>
        <div>
          <div id="mapOptionsWrapper">
            <div *ngxPermissionsOnly="[WPDRole.admin , WPDRole.portAdmin]">
              <select class="map-options" #mapOption [value]="['PORT']" (change)="showHideMarkers($event.target)">
                <option value="PORT">Ports</option>
                <option value="DA">Delivery Agents</option>
                <option value="VESSEL">Vessels</option>
              </select>              
              <mat-form-field class="col-md-12" *ngIf="mapOption.value == 'VESSEL'">
                <mat-label>Type IMO & press enter</mat-label>
                <input matInput name="imo" type="number" [(ngModel)]="selectedPackageType" (keyup.enter)="showVessel($event.target)" />
              </mat-form-field>
            </div>            
          </div>
          <google-map #gMap height="450px"
            width="100%"
            [zoom]="zoom"
            [center]="center"
            [options]="options"        
            (click)="mapClick()"        
            (zoomChanged)="mapZoomChanged()"
            (centerChanged)="mapCenterChanged()"
            (idle)="mapIdle()">

            <map-marker-clusterer 
              [gridSize]=28 
              [maxZoom]=5             
              imagePath="assets/images/m"
              [calculator]="calculator">
              <map-marker
                #markerElem="mapMarker"
                *ngFor="let marker of markers; let i = index;"                
                [position]="marker.position"
                [label]="marker.label"
                [title]="marker.title"
                [options]="marker.options"
                [icon]="marker.icon"
                (mapClick)="openPopup(markerElem, marker)"
                (mapMouseover)="mapShowMarkerInfo(marker)"
                (mapMouseout)="mapShowMarkerInfo(null)"
                (mapRightclick)="mapMarkerRightClick($event, marker, i)"
                (mapDragend)="mapMarkerDragEnd($event, marker, 'PORT')">
              </map-marker>
            </map-marker-clusterer>
            <map-marker
              *ngFor="let marker of selectedAltPorts; let i = index;"                
              [position]="marker.position"                
              [title]="marker.placeName">
            </map-marker>
            <map-marker
              #markerDAElem="mapMarker"
              *ngFor="let marker of mapDeliveryAgentVMs; let i = index;"                
              [position]="marker.position"                
              [title]="marker.name"
              [icon]="daImage"
              [options]="defaultMarkerOptions"
              (mapRightclick)="mapDeliveryAgentRightClick($event, marker, markerDAElem, 'DA')"              
              (mapDragend)="mapMarkerDragEnd($event, marker, 'DA')">
            </map-marker>

            <map-marker
              #markerDepotElem="mapMarker"
              *ngFor="let marker of mapDepotVMs; let i = index;"                
              [position]="marker.position"                
              [title]="marker.name"
              [icon]="(marker.lat == +marker.lng && marker.lat == 0)?depotImageMissing:depotImage"
              [options]="defaultMarkerOptions"
              (mapRightclick)="mapDeliveryAgentRightClick($event, marker, markerDepotElem, 'DEPOT')"              
              (mapDragend)="mapMarkerDragEnd($event, marker, 'DEPOT')">
            </map-marker>

            <!-- <map-polyline [options]="polylineOptions" [path]="polylineOptions.path"></map-polyline> -->
            <map-marker *ngIf="vesselInfo!=null"
              #markerVesselElem="mapMarker"                           
              [position]="vesselInfo"                
              [title]="vesselInfo"
              [icon]="vesselImage"
              [options]="defaultMarkerOptions"
              (mapClick)="openCloseVesselInfoWindow(true)"            
              >
              <map-info-window #infoWindow="mapInfoWindow" [innerHTML]="vesselInfo.notes" style="z-index:99999999999;">
              </map-info-window>
            </map-marker>
                       
          </google-map>
        </div>
        
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon matTooltip="Tabular view">list</mat-icon>
        </ng-template>

        <div class="mat-elevation-z8">
            <mat-form-field>
              <mat-label>Filter</mat-label>
              <input matInput placeholder="Ex. Japan" [formControl]="searchKeywordFilter" (keyup)="applyFilter()">
            </mat-form-field>
          
            <div>
              <table mat-table [dataSource]="tableData" matSort matSortActive="name" matSortDirection="asc" aria-describedby="Port list">
                
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Port</th>
                  <td mat-cell *matCellDef="let row"><a [routerLink]="['/portDetail', row.id]">{{row.name}}</a></td>
                </ng-container>          
                
                <ng-container matColumnDef="notice">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>notice</th>
                  <td mat-cell *matCellDef="let row">{{row.notice==null?"":row.notice + (service.isNumber(row.notice)?" working day(s)":"")}}</td>
                </ng-container>          
                
                <ng-container matColumnDef="prdCnt">
                  <th mat-header-cell class="text-end" *matHeaderCellDef mat-sort-header>Products</th>
                  <td mat-cell class="text-end" *matCellDef="let row">{{row.prdCnt}}</td>
                </ng-container>          
                
                <ng-container matColumnDef="isHallmark">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Port Type</th>
                  <td mat-cell *matCellDef="let row" class="text-center"> <img src="{{row.isHallmark?chevronImageHm:chevronImage}}" alt="Is Hallmark?" /></td>
                </ng-container>          
                
                <ng-container matColumnDef="b">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Barge available</th>
                  <td mat-cell *matCellDef="let row" class="text-center">
                    <mat-icon>{{row.b?'check':'close'}}</mat-icon>
                  </td>
                </ng-container>

                <ng-container matColumnDef="tt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Bulk available</th>
                  <td mat-cell *matCellDef="let row" class="text-center">
                    <mat-icon>{{row.tt?'check':'close'}}</mat-icon>
                  </td>
                </ng-container>           
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
          
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data matching the filter "{{searchKeywordFilter.value}}"</td>
                </tr>
              </table>
            </div>
          
            <mat-paginator [length]="20" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 30, 50]" [showFirstLastButtons]="true"></mat-paginator>
          </div>





        
    </mat-tab>
  </mat-tab-group>

  <div class="hidden-xs">
    <div class="hmKey row">
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div class="portsIcon">5</div>
            <div class="hmPortsDesc">Chevron Lubricants Ports</div>
        </div>
        <div class="col-xl-9 col-lg-9 col-md-6 col-sm-12 col-12">
            <div class="hmPortsIcon">5</div>
            <div class="hmPortsDesc">Multiple Chevron Lubricants Ports and Chevron Hallmark Ports</div>
        </div>
    </div>
    <div class="hmKey row">
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
        <div class="portIcon"></div>
        <div class="hmPortDesc">Chevron Lubricants Port</div>
      </div>
      <div class="col-xl-9 col-lg-9 col-md-6 col-sm-12 col-12">
        <div class="hmPortIcon"></div>
        <div class="hmPortDesc">Chevron Hallmark Port</div>
      </div>
    </div>
  </div>
</div>
<div #contextMenu (click)="handleClick($event)"></div>

<h1>Products</h1>
<div class="mat-elevation-z8">
  <div *ngxPermissionsOnly="[ WPDRole.admin, WPDRole.portAdmin, WPDRole.productAdmin ]">
    <button mat-fab matTooltip="Excel Download"  (click)="downloadExcelFile()" class="excel-download"><mat-icon>download</mat-icon></button>
    <span *ngxPermissionsOnly="[ WPDRole.admin ]">
      <button mat-fab matTooltip="Excel Upload" (click)="fileInput.click()" class="excel-upload"><mat-icon>upload</mat-icon></button>
      <input #fileInput hidden="hidden" type="file" onclick="this.value=null" (change)="uploadFile($event)" accept=".xlsx"/>
    </span>
  </div>
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput placeholder="Ex. Taro" [formControl]="searchKeywordFilter" cvx-input-cache="Product-List-QSearch">
  </mat-form-field>

  <div class="wpd-loading-shade"
       *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div>
    <table mat-table [dataSource]="data" matSort matSortActive="id" matSortDirection="asc" aria-describedby="Product list">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let row">          
          <a [routerLink]="['/portHandling', null, row.id, null]" class="phLink" matTooltip="Port Handling"><mat-icon>link</mat-icon></a>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row"><a [routerLink]="['/product', row.id]">{{row.name}}</a></td>
      </ng-container>

      <ng-container matColumnDef="sapCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>SAP Code</th>
        <td mat-cell *matCellDef="let row">{{row.sapCode}}</td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
        <td mat-cell *matCellDef="let row">{{row.category}}</td>
      </ng-container>

      <ng-container matColumnDef="productClass">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Class</th>
        <td mat-cell *matCellDef="let row">{{row.productClass}}</td>
      </ng-container>

      <ng-container matColumnDef="productType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let row">{{row.productType}}</td>
      </ng-container>

      <ng-container matColumnDef="mpId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>MPID</th>
        <td mat-cell *matCellDef="let row">{{row.mpId}}</td>
      </ng-container>

      <ng-container matColumnDef="isActive">
        <th mat-header-cell class="text-end" *matHeaderCellDef>
          <mat-form-field style="width: 110px;">
            <mat-label>Active?</mat-label>
            <mat-select [formControl]="fIsActive" name="fIsActive" (selectionChange)="filterChange()" cvx-select-cache="Product-List-Active">
              <mat-option *ngFor="let item of booleanList" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>
          </mat-form-field>
          <span mat-sort-header style="position: absolute"><mat-icon aria-hidden="false" fontIcon="swap_vert"></mat-icon></span>
        </th>
        <td mat-cell class="text-center" *matCellDef="let row"> <mat-icon aria-hidden="false" fontIcon="{{row.isActive ? 'check': 'close'}}" class="text-{{row.isActive ? 'success': 'danger'}}"></mat-icon></td>
      </ng-container>

      <ng-container matColumnDef="isSynthetics">
        <th mat-header-cell class="text-end" *matHeaderCellDef>
          <mat-form-field style="width: 130px;">
            <mat-label>Synthetics?</mat-label>
            <mat-select [formControl]="fIsSynthetic" name="fIsSynthetic" (selectionChange)="filterChange()" cvx-select-cache="Product-List-Synthetic">
              <mat-option *ngFor="let item of booleanList" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>
          </mat-form-field>
          <span mat-sort-header style="position: absolute"><mat-icon aria-hidden="false" fontIcon="swap_vert"></mat-icon></span>
        </th>
        <td mat-cell class="text-center" *matCellDef="let row"> <mat-icon aria-hidden="false" fontIcon="{{row.isSynthetics ? 'check': 'close'}}" class="text-{{row.isSynthetics ? 'success': 'danger'}}"></mat-icon></td>
      </ng-container>

      <ng-container matColumnDef="isOEMApproved">
        <th mat-header-cell class="text-end" *matHeaderCellDef>
          <mat-form-field style="width: 140px;">
            <mat-label>OEM Approved?</mat-label>
            <mat-select [formControl]="fIsOEM" name="fIsOEM" (selectionChange)="filterChange()" cvx-select-cache="Product-List-OEM">
              <mat-option *ngFor="let item of booleanList" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>
          </mat-form-field>
          <span mat-sort-header style="position: absolute"><mat-icon aria-hidden="false" fontIcon="swap_vert"></mat-icon></span>
        </th>
        <td mat-cell class="text-center" *matCellDef="let row"> <mat-icon aria-hidden="false" fontIcon="{{row.isOEMApproved ? 'directions_boat': 'close'}}" class="text-{{row.isOEMApproved ? 'success': 'danger'}}"></mat-icon></td>
      </ng-container>      

      <ng-container matColumnDef="portCount">
        <th mat-header-cell class="text-end" *matHeaderCellDef>
          <mat-form-field style="width: 100px;">
            <mat-label>Ports?</mat-label>
            <mat-select [formControl]="fPortExist" name="fPortExist" (selectionChange)="filterChange()" cvx-select-cache="Product-List-PortExist">
              <mat-option *ngFor="let item of booleanList" [value]="item.value">{{item.text}}</mat-option>
            </mat-select>
          </mat-form-field>
          <span mat-sort-header style="position: absolute"><mat-icon aria-hidden="false" fontIcon="swap_vert"></mat-icon></span>
        </th>
        <td mat-cell class="text-end" *matCellDef="let row">{{row.portCount}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openRecord(['/product', row.id])"></tr>      

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data matching the filter "{{tableFilter.quickFilter}}"</td>
      </tr>
    </table>
  </div>

  <mat-paginator [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 30, 50, resultsLength]" [showFirstLastButtons]="true"></mat-paginator>
</div>

import { DataService } from "./data.service";
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from "src/environments/environment";

@Injectable()
export class MapService extends DataService {
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
      super(http, baseUrl, "Map");
  }
  
  getPorts(ports:string, products:string, packageType:string, isBarge:string, isTankTruck:string) : Observable<any> {        
    return this.http.get(environment.serviceURL + "api/" + this.controller 
      + '/GetPorts?ports=' + ports 
      + '&products=' + products 
      + '&packageType=' + packageType 
      + '&isBarge=' + isBarge 
      + '&isTankTruck=' + isTankTruck).pipe(
        catchError(this.handleError)
    );
  }

  getPortDetail(id: BigInteger) : Observable<any> {        
    return this.http.get(environment.serviceURL + "api/" + this.controller + '/GetPortDetail?portId=' + id).pipe(
      catchError(this.handleError)
    );
  }

  getSeaSearcher() : Observable<any> {        
    return this.http.get(environment.serviceURL + "api/" + this.controller + '/GetSeaSearcher').pipe(
      catchError(this.handleError)
    );
  }

  updatePortLocation(id:number, lat:number, lng:number) : Observable<any> {        
    return this.http.post(environment.serviceURL + "api/" + this.controller 
      + '/UpdatePortLocation?id=' + id 
      + '&lat=' + lat 
      + '&lng=' + lng,null).pipe(
        catchError(this.handleError)
    );
  }

  getDAs() : Observable<any> {        
    return this.http.get(environment.serviceURL + "api/" + this.controller + '/GetDAs').pipe(
      catchError(this.handleError)
    );
  }
  
  updateDALocation(id:number, lat:number, lng:number) : Observable<any> {        
    return this.http.post(environment.serviceURL + "api/" + this.controller 
      + '/UpdateDALocation?id=' + id 
      + '&lat=' + lat 
      + '&lng=' + lng,null).pipe(
        catchError(this.handleError)
    );
  }

  getDepots(vendorNumber: string) : Observable<any> {        
    return this.http.get(environment.osApiURL + "DA/GetDepots?vendorNumber=" + vendorNumber).pipe(
      catchError(this.handleError)
    );
  }

  updateDepotLocation(id:string, lat:number, lng:number) : Observable<any> {        
    return this.http.post(environment.osApiURL 
      + "DA/UpdateDepotLocation?id=" + id 
      + '&lat=' + lat 
      + '&lng=' + lng,null).pipe(
            catchError(this.handleError)
    );
  }

  getDepotPorts(depotId: number) : Observable<any> {        
    return this.http.get(environment.osApiURL + "DA/GetDepotPorts?depotId=" + depotId).pipe(
      catchError(this.handleError)
    );
  }

  getAutoList(type:"Product"|"Port"|"Category", query?:string) : Observable<any> {        
    return this.http.get(environment.serviceURL + "api/" + this.controller 
      + '/GetAutoList?t=' + type
      + '&q=' + query).pipe(
        catchError(this.handleError)
    );
  }

  validateCacheKey(key:string) : Observable<any> {        
    return this.http.get(environment.serviceURL + "api/" + this.controller + '/ValidateCacheKey?k=' + key).pipe(
        catchError(this.handleError)
    );
  }
    
  clearCacheKey() : Observable<any> {        
    return this.http.get(environment.serviceURL + "api/" + this.controller + '/ClearCacheKey').pipe(
        catchError(this.handleError)
    );
  }

  getFuelPorts() : Observable<any> {        
    return this.http.get(environment.serviceURL + "api/" + this.controller 
      + '/GetFuelPorts').pipe(
        catchError(this.handleError)
    );
  }

  getFuelPortDetail(id: BigInteger) : Observable<any> {        
    return this.http.get(environment.serviceURL + "api/" + this.controller + '/GetFuelPort?portId=' + id).pipe(
      catchError(this.handleError)
    );
  }

  getAisPositionHistory(vesselId: number) {
    return this.http.get(environment.osApiURL + 'SeaSearcher/AisPositionHistory?vesselImo=' + vesselId).pipe(
        catchError(this.handleError)
    );
  }

  getVesselLocation(vesselId: number) {
    return this.http.get(environment.osApiURL + 'SeaSearcher/GetVesselLocation?vesselImo=' + vesselId).pipe(
        catchError(this.handleError)
    );
  }
}   

import { AuthService } from './../services/auth.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { Component, Inject, OnInit } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { BaseComponent } from '../common/base.component';

@Component({
  selector: 'wpd-home',
  templateUrl: './home.component.html',
})
export class HomeComponent extends BaseComponent implements OnInit {
  userName = '';
  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth,public authService: AuthService) {
    super();
  }
  async ngOnInit() {    
    this.userName = await this.authService.getUserName();
  }
}

import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppError } from '../common/app-error';
import { SampleDto, TableXFilter } from './demo.component';


@Injectable({
  providedIn: 'root'
})
export class DummyService {
  data: SampleDto[];

  constructor(protected http: HttpClient) {
  }

  getList(filter: TableXFilter): Observable<any> {
    return this.http.post("https://outservice-dev.azure.chevron.com/FAST/GetSamples", filter).pipe(
     catchError(this.handleError)
    );
 }

  protected handleError(error: HttpErrorResponse) : any  {
    if (error.status === 400)
        return throwError(() => new AppError(error));       
    if (error.status === 404)
        return throwError(() => new AppError(error)); 

    return throwError(() => new AppError(error)); 
  }
}


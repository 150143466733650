import { NgxPermissionsService } from 'ngx-permissions';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { APIResultVM } from '../models/apiResultVM';
import { WPDAuthUser } from '../models/wpdAuthUser';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { filter, firstValueFrom, map } from 'rxjs';
import { OutSystemService } from './os.service';

@Injectable()
export class AuthService {  
  public userSessionKey = 'WPD.AuthUser';
  constructor(public http: HttpClient, 
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth, 
    public permission: NgxPermissionsService, 
    public oktaAuthStateService: OktaAuthStateService,
    public osService: OutSystemService) { }   


  async isAuthenticated(): Promise<boolean> {
    return await firstValueFrom(this.oktaAuthStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState),
      map((authState: AuthState) => authState.isAuthenticated ?? false)
    ));
  }

  async getUserName(): Promise<string> {
    return await firstValueFrom(this.oktaAuthStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.accessToken?.claims.sub ?? authState.idToken?.claims.email ?? '')
    ));
  }

  private getLoggedUser() {
    this.clearPermissions();
    return this.http.get<APIResultVM<WPDAuthUser>>(environment.serviceURL + 'api/WPDAuth/GetLoggedUser');
  }

  private clearPermissions() {
    this.permission.flushPermissions();    
    sessionStorage.clear();
  }

  public async logout() {
    await this.oktaAuth.signOut();
    this.clearPermissions();
  }
  
  getLoggedOnUserFromSession(): WPDAuthUser {
    const userSession = JSON.parse(sessionStorage.getItem(this.userSessionKey) ?? "{}");
    if(userSession.userID) this.setLastLoggedDate();
    return userSession;
  }

  loadPermissionsFromSession() {   
    const user = this.getLoggedOnUserFromSession();
    if(!user?.userID) return;
    const roles = user?.roles?.map(x=><string><unknown>x)??[];
    this.permission.loadPermissions(roles);   
  }

  async getLoggedOnUser():Promise<WPDAuthUser|undefined> {
    return new Promise(async (resolve) => {
      const user =  this.getLoggedOnUserFromSession();
      const userName = await this.getUserName();
      if(!user.userID || user.userID != userName) {
        this.getLoggedUser().subscribe((resp)=>{
          if (resp.o != null) {          
            sessionStorage.setItem(this.userSessionKey, JSON.stringify(resp.o));
          }
          else {
            sessionStorage.setItem(this.userSessionKey, '{"userID": "' + userName + '"}');
          }
          
          resolve(resp.o);
        })
      }
      else{
        resolve(this.getLoggedOnUserFromSession());
      }
    });    
  }

  private setLastLoggedDate() {
    let lastLoggedKey = this.userSessionKey + "_LastLogged"
    let currentDate = new Date();
    let lastLogged: Date =  new Date(sessionStorage.getItem(lastLoggedKey)?? "1900-01-01");
    let hours = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours()) - Date.UTC(lastLogged.getFullYear(), lastLogged.getMonth(), lastLogged.getDate(), lastLogged.getHours()) ) /(1000 * 60 * 60 * 24));
    if(hours > 12)
    {     
      this.osService.setUserLoggedInDate().subscribe(()=>{
        sessionStorage.setItem(lastLoggedKey, currentDate.toISOString());
      });
    }
    
  }
}   

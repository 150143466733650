import { Component, OnInit, Inject } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { DataService } from '../services/data.service';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { APIResultVM } from '../models/apiResultVM';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponent } from './base.component';

export type DialogDataSubmitCallback<T> = (row: T) => void;

@Component({
  selector: 'cvx-base-upsert',
  template: `
    <p>
      base edit view!
    </p>
  `
})

export class BaseUpsert<T> extends BaseComponent implements OnInit {
  id: BigInteger;
  data: T = <T>{};
  isLoadingResults = true;
  result: APIResultVM<T> = {};
  isDialog = false;

  constructor(protected service: DataService, protected route: ActivatedRoute, protected snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public contextData?: { callback: DialogDataSubmitCallback<T>; id: BigInteger },
    protected dialogRef?: MatDialogRef<BaseUpsert<T>>
    ) {
    super()
  }

  ngOnInit() {

    this.route.paramMap.subscribe(paramMap => {
      this.id = <BigInteger><unknown>(paramMap.get('id'));
      if (!this.id) {
        this.id = <BigInteger>(this.contextData?.id??"0");
        this.isDialog = (this.contextData?.id != null);
      }
      // Call your API service and update the local data
      this.isLoadingResults = true;
      return this.service.getById(this.id).subscribe({
        next: (data: T) => {          
          this.data = data;
          this.dataLoaded();
        },
        error: (e) => { console.log(e) },
        complete: () => { this.isLoadingResults = false; },
      });
    });    
  }

  dataLoaded() { return }

  updateData()
  {
    this.isLoadingResults = true;
    this.result = {};
        
    this.service.update(this.data).subscribe({
      next: (data: APIResultVM<T>) => {
        this.result = data;
        if (this.result.e != null) {
          this.snackBar.open(this.result.e ?? "", "Error");
        }
        else if (this.result.i != null) {
          if (this.contextData?.callback) this.contextData?.callback(this.data);
          this.snackBar.open(this.result.i ?? "Updated Successfully", "Success", { duration: 2 * 1000 });
        }

      },      
      error: (e) => { this.snackBar.open("Unhandled Exception, please contact administrator!!!", "Error"); console.log(e) },
      complete: () => { this.isLoadingResults = false; }, 
    });
  } 

}




<div class="form-horizontal">
    <div class="wpd-loading-shade"
        *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <h3 mat-dialog-title>Product: {{data.id}} / Material No.: {{data._SAP_ProductID}} / MPId: {{data._MPId}} 
        <a [routerLink]="['/portHandling', null, data.id, null]" class="phLink" matTooltip="Port Handling"><mat-icon>link</mat-icon></a>
        <button mat-icon-button mat-dialog-close class="float-right" *ngIf="isDialog"><mat-icon>close</mat-icon></button>
    </h3>
    <form #loginForm="ngForm" (ngSubmit)="updateData()" mat-dialog-content>        
            
        <mat-form-field class="col-md-6">
            <mat-label>Name</mat-label>
            <input matInput name="_Name" [(ngModel)]="data._Name" type="text" readonly />
        </mat-form-field>
        <mat-form-field class="col-md-3">
            <mat-label>Category</mat-label>
            <input matInput name="_CategoryName" [(ngModel)]="data._CategoryName" type="text" readonly />
        </mat-form-field>
        <mat-form-field class="col-md-3">
          <mat-label>Material Pricing Group</mat-label>
          <input matInput name="_MaterialPricingGroupName" [(ngModel)]="data._MaterialPricingGroupName" type="text" readonly />
      </mat-form-field>
        <fieldset>
            <legend>Flags:</legend>
            <div style="display: initial;">
              <mat-label class="bold">Product Class: </mat-label>
              <mat-radio-group [(ngModel)]="data.productClassId" name="productClassId">
                <mat-radio-button [value]="1">Main Grade</mat-radio-button>
                <mat-radio-button [value]="2">Ancillary</mat-radio-button>
              </mat-radio-group>
            </div>            
            <mat-checkbox class="flag-item" name="isSynthetics" [(ngModel)]="data.isSynthetics">Synthetics?</mat-checkbox>            
            <mat-checkbox class="" name="_IsActive" [(ngModel)]="data._IsActive" [disabled]="true">Is Active?</mat-checkbox>
            <mat-form-field class="col-md-2">
              <mat-label>Is OEMApproved</mat-label>
              <mat-select [(ngModel)]="data.isOEMApproved" name="isOEMApproved">
                <mat-option *ngFor="let item of booleanList" [value]="item.value">{{item.text}}</mat-option>
              </mat-select>
            </mat-form-field>           
            <mat-form-field class="col-md-2">
              <mat-label>Restricted Use?</mat-label>
              <mat-select [(ngModel)]="data.restrictedUse" name="restrictedUse">
                <mat-option *ngFor="let item of booleanList" [value]="item.value">{{item.text}}</mat-option>
              </mat-select>
            </mat-form-field>
            
            <div *ngIf="data.restrictedUse" class="col-md-2" style="display: inline-flex;">
              <ng-select  placeholder="Products to Override" name="selectedProductsToOverride"
              [items]="wpdMapComponent.products" bindLabel="text" bindValue="id" [(ngModel)]="selectedProductsToOverride"
              [multiple]="true" [hideSelected]="true" 
              (add)="updateProductsToOverride()" (remove)="updateProductsToOverride()" (clear)="updateProductsToOverride()">
                <ng-template ng-option-tmp let-item="item.text" let-search="searchTerm">
                  <span [ngOptionHighlight]="search">{{item}}</span>
                </ng-template>
              </ng-select>
            </div>
        </fieldset>
        <mat-form-field class="col-md-12">
          <mat-label>PDS URL</mat-label>
          <input matInput name="pdS_URL" [(ngModel)]="data.pdS_URL" type="text" />
        </mat-form-field>
        <div class="row">        
          <mat-form-field class="col-md-3">
            <mat-label>SDS</mat-label>
            <input matInput name="sds" [(ngModel)]="data.sds" type="number" />
          </mat-form-field>
          <mat-form-field class="col-md-3">
            <mat-label>Doc No.</mat-label>
            <input matInput name="docNo" [(ngModel)]="data.docNo" type="text" />
          </mat-form-field>
          <mat-form-field class="col-md-3">
            <mat-label>Doc Edition</mat-label>
            <input matInput name="docEdition" [(ngModel)]="data.docEdition" type="text" />
          </mat-form-field>
          <mat-form-field class="col-md-3">
            <mat-label>Doc Issue Date</mat-label>
            <input matInput name="docIssueDT" [(ngModel)]="data.docIssueDT" type="text" />
          </mat-form-field>
        </div>
        <div class="row">        
          <mat-form-field class="col-md-3">
            <mat-label>SDS_X</mat-label>
            <input matInput name="sdS_X" [(ngModel)]="data.sdS_X" type="number" />
          </mat-form-field>
          <mat-form-field class="col-md-3">
            <mat-label>Doc No. X</mat-label>
            <input matInput name="docNo_X" [(ngModel)]="data.docNo_X" type="text" />
          </mat-form-field>
          <mat-form-field class="col-md-3">
            <mat-label>Doc Edition X</mat-label>
            <input matInput name="docEdition_X" [(ngModel)]="data.docEdition_X" type="text" />
          </mat-form-field>
          <mat-form-field class="col-md-3">
            <mat-label>Doc Issue Date X</mat-label>
            <input matInput name="docIssueDT_X" [(ngModel)]="data.docIssueDT_X" type="text" />
          </mat-form-field>
        </div>
        <div class="row">        
          <mat-form-field class="col-md-3">
            <mat-label>MD Issue DT</mat-label>
            <input matInput name="sdsIssueDT" [(ngModel)]="data.sdsIssueDT" type="number" />
          </mat-form-field>
          <mat-form-field class="col-md-3">
            <mat-label>Supplier Information</mat-label>
            <input matInput name="supplierInformation" [(ngModel)]="data.supplierInformation" type="text" />
          </mat-form-field>         
          <mat-form-field class="col-md-3">
            <mat-label>Declaration Date</mat-label>
            <input matInput name="declarationDT" [(ngModel)]="data.declarationDT" type="text" />
          </mat-form-field>
        </div>
        <div class="row">        
          <mat-form-field class="col-md-3">
            <mat-label>Remark 1</mat-label>
            <input matInput name="remark1" [(ngModel)]="data.remark1" type="number" />
          </mat-form-field>
          <mat-form-field class="col-md-3">
            <mat-label>Remark 2</mat-label>
            <input matInput name="remark2" [(ngModel)]="data.remark2" type="text" />
          </mat-form-field>         
          <mat-form-field class="col-md-3">
            <mat-label>Remark 3</mat-label>
            <input matInput name="remark3" [(ngModel)]="data.remark3" type="text" />
          </mat-form-field>
        </div>
        <mat-form-field class="col-md-12">
          <mat-label>Product Information</mat-label>
          <input matInput name="productInformation" [(ngModel)]="data.productInformation" type="text" />
        </mat-form-field>
        <mat-form-field class="col-md-12">
          <mat-label>Internal Notes</mat-label>
          <input matInput name="internalNotes" [(ngModel)]="data.internalNotes" type="text" />
        </mat-form-field>

        <mat-accordion>
          <mat-expansion-panel  (opened)="false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Ports
              </mat-panel-title>
              <mat-panel-description>
                List of available Ports
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-sm-3" *ngFor="let p of data._Ports">
                <a [routerLink]="['/port', p.id]">{{p.name}}<mat-icon *ngIf="!p.visible" matTooltip="Not visible to customers">location_off</mat-icon></a>
              </div>              
            </div>        
          </mat-expansion-panel>        
        </mat-accordion>
        
        <div class="padding-top-24" *ngIf="data.canUpdate" mat-dialog-actions>
          <div class="col-md-offset-2 col-md-12">
            <div [ngClass]="'alert ' + result.e == null ?'alert-danger' : 'alert-success'"  [innerHTML]="result.e??result.i"></div>
            <button mat-raised-button color="primary" type="submit">Save</button>
          </div>
        </div>
    </form>

</div>

